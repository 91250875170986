import { createSelector } from 'reselect';

import { Subscription } from 'state-domains/domain/subscription/types';
import { isCompleteSelector, isFailedSelector, isPendingSelector } from 'state-domains/utils';

import { AsyncState, ShimState } from '../../../types';
import { CurrentSubscription, RecentProject, UserState } from '../types';

const userState = (state: Partial<ShimState>): UserState => {
    const { user = {} as UserState } = state || {};
    return user;
};

const accountPreferences = createSelector(userState, ({ accountPreferences: prefs }) => prefs);

const loadRecentProjectsState = createSelector(
    userState,
    ({ loadRecentProjectsState: dhLoadRecentProjectsState }): AsyncState =>
        dhLoadRecentProjectsState,
);
const currentUserId = createSelector(userState, ({ id = '' }: UserState): string => id);

const currentUserEmail = createSelector(userState, ({ email = '' }: UserState): string => email);

const isFailedWithAuthError = createSelector(
    userState,
    ({ error = {} as Error }: UserState): boolean => error?.status === 401 || error?.status === 403,
);

const currentUserUid = createSelector(userState, ({ uid = '' }: UserState): string => uid);

const currentUserName = createSelector(
    userState,
    ({ profile: { name = '' } = {} as any }: UserState) => name,
);

const currentUserProfile = createSelector(userState, ({ profile }: UserState) => profile);

// for notifications, this should probably raise an error if its undefined
const audienceUid = currentUserUid;

const recentProjects = createSelector(
    userState,
    ({ recentProjects = [] }: UserState): RecentProject[] => recentProjects,
);

const isUserModalOpen = createSelector(
    userState,
    ({ isUserModalOpen: usIsUserModalOpen }: UserState): boolean => usIsUserModalOpen,
);

const currentSubscription = createSelector(
    userState,
    ({ selected = {} as CurrentSubscription }: UserState): CurrentSubscription => selected,
);

const currentRole = createSelector(
    userState,
    ({
        selected = {} as CurrentSubscription,
        subscriptions: subSubscriptions = [] as Subscription[],
    }: UserState): string =>
        subSubscriptions.find((sub: Subscription) => sub.subscriptionId === selected.id)?.role ??
        '',
);
const subscriptions = createSelector(
    userState,
    ({ subscriptions: subSubscriptions = [] }: UserState): Subscription[] => subSubscriptions,
);

const setDefaultSubscription = createSelector(
    userState,
    ({ defaultSubscriptionState = {} as CurrentSubscription }: UserState): AsyncState =>
        defaultSubscriptionState,
);

const accounts = createSelector(
    userState,
    ({ userAccounts: accountData }: UserState): Subscription[] => accountData.accounts,
);

export const selectors = {
    currentUserUid,
    currentUserName,
    audienceUid,
    isCurrentUserLoaded: isCompleteSelector(userState),
    currentUserId,
    currentUserEmail,
    isFailedWithAuthError,
    isCurrentUserFailed: isFailedSelector(userState),
    recentProjects,
    isRecentProjectsLoaded: isCompleteSelector(loadRecentProjectsState),
    isRecentProjectsFailed: isFailedSelector(loadRecentProjectsState),
    isUserModalOpen,
    isUserStatePending: isPendingSelector(userState),
    currentSubscription,
    isCurrentSubscriptionPending: isPendingSelector(currentSubscription),
    isCurrentSubscriptionComplete: isCompleteSelector(currentSubscription),
    isCurrentSubscriptionFailed: isFailedSelector(currentSubscription),
    currentRole,
    subscriptions,
    accountPreferences,
    currentUserProfile,
    setDefaultSubscription,
    isSetDefaultSubscriptionPending: isPendingSelector(setDefaultSubscription),
    isSetDefaultSubscriptionComplete: isCompleteSelector(setDefaultSubscription),
    isSetDefaultSubscriptionFailed: isFailedSelector(setDefaultSubscription),
    accounts,
};
