import { ActivityMap } from 'state-domains/domain';

export interface DataGridProps {
    projectId: string;
    tableView: string;
    activity: ActivityMap;
    isSample: boolean;
    linkedToLithology: boolean;
    drillholeId: string;
    readOnly: boolean;
    setState: Function;
    setInitiateSave: Function;
    setNavigateData: Function;
    linkedRowReference?: string;
}

export enum OverwriteType {
    FILL_DOWN = 'FILL_DOWN',
    PASTE = 'PASTE',
    NONE = 'NONE',
}

export enum AlertModalOptions {
    ROW_LIMIT = 'ROW_LIMIT',
    LENGTH_EXCEEDED = 'LENGTH_EXCEEDED',
    INVALID_INSERT = 'INVALID_INSERT',
    READ_ONLY = 'READ_ONLY',
    CLEAR_REQUIRED_COLUMN = 'CLEAR_REQUIRED_COLUMN',
    SINGLE_ROW_TABLE = 'SINGLE_ROW_TABLE',
    FILTER_APPLIED = 'FILTER_APPLIED',
    COLUMNS_EXCEEDED = 'COLUMNS_EXCEEDED',
    INVALID_PASTE_VALUE = 'INVALID_PASTE_VALUE',
    INVALD_PASTE_COLUMN = 'INVALID_PASTE_COLUMN',
    INVALID_PASTE_COLUMN_GENERIC = 'INVALID_PASTE_COLUMN_GENERIC',
    INVALID_PASTE_CELL = 'INVALID_PASTE_CELL',
    NO_DATA_TO_COPY = 'NO_DATA_TO_COPY',
    TEMPORARY_ROW = 'TEMPORARY_ROW',
    UNIQUE_COLUMN = 'UNIQUE_COLUMN',
}

export interface TableViewState {
    tableView: string;
    linkedToLithology: boolean;
    isSample: boolean;
    readOnly: boolean;
    isSingleRowTable: boolean;
    isCoordinatesTable: boolean;
}

export interface TableViewDrillHoleIds {
    tableView: string;
    drillholeId: string;
}
