import { Observable, of as observableOf } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { buildUserProfileUrl } from '../../../network/urls';
import { convertToCamel, sendRetrieveRequest } from '../../../utils';
import { JsonUser } from '../types';

export const loadCurrentUser = (): Observable<JsonUser> => {
    const url = buildUserProfileUrl();
    return sendRetrieveRequest(url).pipe(
        switchMap(({ response }) => observableOf(convertToCamel<JsonUser>(response))),
    );
};
