import { fonts, getSpacing } from '@local/web-design-system';
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import { commonStyles } from 'src/styles';

export const styles = (theme: Theme) => {
    const dropdownIconHeightRatio = 1.5;
    const dropdownIconWidthRatio = 1.5;
    const dropdownIconHeight = getSpacing(theme, dropdownIconHeightRatio);
    const dropdownIconWidth = getSpacing(theme, dropdownIconWidthRatio);

    return {
        paper: {
            borderRadius: 0,
            boxShadow: 'none',
            border: `1px solid ${theme.palette.grey[500]}`,
        },
        select: {
            backgroundColor: theme.palette.grey[300],
            ...fonts.body2,
            lineHeight: theme.spacing(2),
            '&:after': {
                borderBottom: 'none !important',
            },
            '&:before': {
                borderBottom: 'none !important',
            },
        },
        selectMenu: {
            paddingLeft: theme.spacing(),
            paddingTop: theme.spacing(1.5),
            paddingBottom: theme.spacing(1.5),
        },
        menuitem: {
            ...fonts.body2,
        },
        icon: {
            width: dropdownIconWidth,
            height: dropdownIconHeight,
            top: `calc(50% - ${dropdownIconHeight / 2}px)`,
            right: `${dropdownIconWidth / 2}px`,
        },
        arrowRightExtendedIcon: {
            paddingLeft: `${theme.spacing(0.5)} !important`,
        },
        arrowLeftDoubleIcon: {
            paddingRight: theme.spacing(0.5),
        },
        arrowRightDoubleIcon: {
            paddingLeft: `${theme.spacing(1.5)} !important`,
        },
        button: {
            ...commonStyles(theme).paginationButton,
        },
    };
};

export const useStyles = makeStyles()(styles);
