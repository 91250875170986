import { format } from 'date-fns';

import { MXDTimestamp } from 'state-domains/domain';

const DEFAULT_TRUNCATE_LENGTH = 20;
export function truncateInputString(input: string, truncate = DEFAULT_TRUNCATE_LENGTH) {
    return input.length > truncate ? `${input.substring(0, truncate)}...` : input;
}

export const generateObjectId =
    () =>
    (m = Math, d = Date, h = 16, s = (s: any) => m.floor(s).toString(h)) =>
        s(d.now() / 1000) + ' '.repeat(h).replace(/./g, () => s(m.random() * h));

const DATE_DISPLAY_FORMAT = 'MMM dd, yyyy';
const DATE_TIME_DISPLAY_FORMAT = 'MMM dd, yyyy h:mma';
/**
 *
 * @returns string in the format of 'MMM dd, yyyy' or empty string if no date
 * @param dateObj
 * @param isDateTime
 */
export const formatDateToDisplay = (dateObj: MXDTimestamp | string | undefined, isDateTime=false) => {
    if (typeof dateObj === 'string') {
        return dateObj;
    }
    if (!dateObj?.date) {
        return '';
    }
    return format(new Date(dateObj.date), isDateTime ? DATE_TIME_DISPLAY_FORMAT : DATE_DISPLAY_FORMAT);
};
