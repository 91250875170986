import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { evoOrgUrl } from 'state-domains/network';
import { convertToCamel } from 'state-domains/utils/typeCast';

import { sendRetrieveRequest } from '../../../utils';
import { EvoOrgState } from '../../integrations/types';

export const loadEvoOrg = (): Observable<EvoOrgState> => {
    const obs = sendRetrieveRequest(evoOrgUrl());
    return obs.pipe(
        switchMap(({ response }: AjaxResponse<EvoOrgState>) => {
            const workspaces = convertToCamel<EvoOrgState>(response);
            return observableOf(workspaces);
        }),
    );
};
