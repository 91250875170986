import { ArrowDownIcon, fonts, theme } from '@local/web-design-system';
import { Grid, Typography } from '@mui/material';
import * as React from 'react';
import {
    components,
    ControlProps,
    CSSObjectWithLabel,
    GroupBase,
    OptionProps,
    StylesConfig,
} from 'react-select';

import { MODAL_AUTOMATION_IDS } from 'src/components/Drillhole/DrillholeModal';

import { mainFieldStyle, mainFontTypes } from './common.styles';

export const inputFonts = {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    lineHeight: '17px',
    color: '#1D1F23',
    letterSpacing: '0.1px',
    fontWeight: fonts.weights.regular,
    fontSize: '13px',
};

export const noOptionStyle = {
    color: '#B5B5B5',
    whiteSpace: 'pre-wrap',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '1.5',
    fontWeight: 400,
    letterSpacing: '0.1px',
    margin: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordBreak: 'break-word' as const,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
};

export const dragAndDropOptionStyles = {
    justifyContent: 'center',
    alignItems: 'center',
    width: 'auto',
    minWidth: '32px',
    height: '32px',
    color: '#979797',
};

export const selectControl = (isFocused: boolean): CSSObjectWithLabel => ({
    ...inputFonts,
    width: '100%',
    height: theme.spacing(5),
    border: isFocused ? '1px solid #FFFFFF !important' : undefined,
});

export const selectPlaceHolder: CSSObjectWithLabel = {
    ...inputFonts,
    color: '#808080',
    fontStyle: 'italic',
};

export const DropdownIndicatorOverride = (props: any) => (
    <components.DropdownIndicator {...props}>
        <ArrowDownIcon
            fontSize="small"
            automation-id={MODAL_AUTOMATION_IDS.DROPDOWN_INDICATOR}
            sx={{ opacity: props.isDisabled ? '0.4' : '1' }}
        />
    </components.DropdownIndicator>
);

export const MultiValueSelectOptionOverride = (
    props: OptionProps & { noPadding?: boolean; isDisabled?: boolean },
) => {
    const label = props.label;
    const Icon = (props.data as any)?.Icon;
    return (
        <components.Option {...props}>
            {Icon ? (
                <Grid
                    container
                    sx={{
                        height: 'inherit',
                        alignItems: 'center',
                        whiteSpace: 'pre-wrap',
                        flexWrap: 'nowrap',
                        ...(props.isDisabled && { opacity: '0.5' }),
                    }}
                >
                    <Grid
                        item
                        sx={{
                            paddingRight: '8px',
                            paddingLeft: '4px',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Icon
                            whiteVariant={props.isFocused}
                            sx={{
                                paddingRight: '4px',
                                width: '20px',
                                height: '20px',
                                marginTop: '2px',
                            }}
                        />
                    </Grid>
                    <Grid item sx={{ width: '80%' }}>
                        <Typography
                            sx={{
                                ...mainFontTypes,
                                fontSize: '13px',
                                fontWeight: '400',
                                ...(!props.noPadding && { padding: '4px 0px 4px 0px' }),
                            }}
                        >
                            {label}
                        </Typography>
                    </Grid>
                </Grid>
            ) : (
                <Grid sx={{ width: '90%', ...(props.isDisabled && { opacity: '0.5' }) }}>
                    <Typography
                        sx={{
                            ...mainFontTypes,
                            fontSize: '13px',
                            fontWeight: '400',
                            ...(!props.noPadding && { padding: '4px 0px 4px 0px' }),
                        }}
                    >
                        {label}
                    </Typography>
                </Grid>
            )}
        </components.Option>
    );
};

export const SingleValueIconContainerOverride = (props: any) => {
    const value = props?.getValue()[0];
    const label = value?.name ?? '';
    const Icon = value?.Icon;
    const isDisabled = props.isDisabled;

    return (
        <Grid
            container
            display="flex"
            flexDirection="row"
            alignSelf="center"
            sx={{ opacity: isDisabled ? '0.4' : '1' }}
            wrap="nowrap"
        >
            {Icon && (
                <Grid
                    item
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        paddingRight: '8px',
                        paddingLeft: mainFieldStyle.paddingLeft,
                        '>img': {
                            marginTop: '-2px',
                        },
                    }}
                >
                    <Icon />
                </Grid>
            )}
            <Grid item>
                <Typography
                    sx={{
                        ...mainFontTypes,
                        fontSize: mainFieldStyle.fontSize,
                        fontWeight: '400',
                        display: 'flex',
                        alignItems: 'center',
                        lineHeight: '24px',
                    }}
                >
                    {label}
                </Typography>
            </Grid>
        </Grid>
    );
};

export const SingleValueContainerOverride = (props: any) => {
    const value = props?.getValue()[0];
    const label = value?.name ?? '';
    const isDisabled = props.isDisabled;

    return (
        <Grid
            container
            flexDirection="row"
            alignSelf="center"
            sx={{ opacity: isDisabled ? '0.4' : '1', paddingLeft: mainFieldStyle.paddingLeft }}
            wrap="nowrap"
        >
            <Typography
                sx={{
                    ...mainFontTypes,
                    fontSize: mainFieldStyle.fontSize,
                    fontWeight: '400',
                    display: 'flex',
                    alignItems: 'center',
                    flex: 1,
                }}
            >
                {label}
            </Typography>
        </Grid>
    );
};

export const selectStylesBackground: StylesConfig = {
    control: (
        styles: CSSObjectWithLabel,
        { isFocused }: ControlProps<unknown, boolean, GroupBase<unknown>>,
    ) =>
        ({
            ...styles,
            ...selectControl(isFocused),
            width: '100%',
            backgroundColor: '#F2F2F2',
        }) as CSSObjectWithLabel,
    placeholder: (styles: CSSObjectWithLabel) => ({
        ...styles,
        ...selectPlaceHolder,
    }),
    menuPortal: (base: CSSObjectWithLabel) =>
        ({
            ...base,
            zIndex: 9999,
        }) as CSSObjectWithLabel,
    valueContainer: (base: CSSObjectWithLabel) =>
        ({
            ...base,
            caretColor: 'transparent',
            display: 'flex',
            flexWrap: 'nowrap',
            'div > input': {
                cursor: 'default',
            },
        }) as CSSObjectWithLabel,
    input: (base: CSSObjectWithLabel) => ({
        ...base,
    }),
};

export const selectStylesDefaultBackground: StylesConfig = {
    control: (
        styles: CSSObjectWithLabel,
        { isFocused }: ControlProps<unknown, boolean, GroupBase<unknown>>,
    ) =>
        ({
            ...styles,
            ...selectControl(isFocused),
            width: '100%',
            height: 'fit-content',
            minHeight: '40px',
        }) as CSSObjectWithLabel,
    placeholder: (styles: CSSObjectWithLabel) => ({
        ...styles,
        ...selectPlaceHolder,
    }),
    menuPortal: (base: CSSObjectWithLabel) =>
        ({
            ...base,
            zIndex: 9999,
        }) as CSSObjectWithLabel,
};
