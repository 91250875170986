import { v4 as uuidv4 } from 'uuid';

import {
    COORDINATES_ID,
    COORDINATES_LABEL,
    FILTER_TYPES,
    GRID_COLUMN_ID,
} from 'state-domains/constants';
import {
    AccountUser,
    ActivityGroupMap,
    ActivityMap,
    Column,
    CustomColour,
    DefaultPermission,
    DrillHoleReferencesIntervalType,
    FilterOperator,
    GridEntry,
    Header,
    HeaderType,
    List,
    MXColour,
    SubscriptionDataState,
    SubscriptionState,
    SystemFilter,
    Table,
    TableView,
    UsersByType,
    UserType,
    UserWithType,
    Category,
    SampleDispatchHeader,
    SampleWorkflow,
} from 'state-domains/domain';
import { BaseAction } from 'state-domains/types';

export const SubscriptionActionNames = {
    loadAccountActivities: 'loadAccountActivities',
    loadAccountInformation: 'loadAccountInformation',
    loadTableViews: 'loadTableViews',
    loadTables: 'loadTables',
    loadLists: 'loadLists',
    loadListCategories: 'loadListCategories',
    loadTableCategories: 'loadTableCategories',
    loadHeaderCategories: 'loadHeaderCategories',
    loadHeaders: 'loadHeaders',
    loadHeaderTypes: 'loadHeaderTypes',
    loadSystemFilters: 'loadSystemFilters',
    loadFilterOperators: 'loadFilterOperators',
    loadDefaultPermissions: 'loadDefaultPermissions',
    loadCoordinates: 'loadCoordinates',
    loadActivityGroups: 'loadActivityGroups',
    loadActivityCategories: 'loadActivityCategories',
    loadColours: 'loadColours',
    saveColour: 'saveColour',
    deleteColour: 'deleteColour',
    loadSampleResultsRankedUnits: 'loadSampleResultsRankedUnits',
    loadSampleDispatchHeaders: 'loadSampleDispatchHeaders',
    loadSampleWorkflows: 'loadSampleWorkflows',
};

const sortByIndex = (collection: any[], sortKey: string) => {
    collection.sort((a: any, b: any) => (a[sortKey] > b[sortKey] ? 1 : -1));
};

export const processLoadedActivities = (
    _state: SubscriptionDataState | SubscriptionState,
    action: BaseAction,
) => {
    const dict: { [key: string]: ActivityMap } = {};
    const {
        payload: { result: items = [] as ActivityMap[] },
    } = action;
    items.forEach((element: ActivityMap) => {
        dict[element.id] = element;
    });
    return dict;
};

export const processLoadedAccountInformation = (
    _state: SubscriptionDataState | SubscriptionState,
    action: BaseAction,
) => {
    const {
        payload: {
            result: { account, users },
        },
    } = action;

    const { user = [], shift = [], reviewer = [], inactive = [] } = users as UsersByType;
    const allUsersWithType = [
        ...user.map((usr: AccountUser) => ({ ...usr, type: UserType.User })),
        ...shift.map((usr: AccountUser) => ({ ...usr, type: UserType.Shift })),
        ...reviewer.map((usr: AccountUser) => ({ ...usr, type: UserType.Reviewer })),
        ...inactive.map((usr: AccountUser) => ({ ...usr, type: UserType.Inactive })),
    ];
    const usersMap: { [key: string]: UserWithType } = {};
    allUsersWithType.forEach((usr: UserWithType) => {
        usersMap[usr.id] = { ...usr };
    });
    return { account, usersMap };
};

export const processLoadedTableViews = (
    _state: SubscriptionDataState | SubscriptionState,
    action: BaseAction,
) => {
    const dict: { [key: string]: TableView } = {};
    const {
        payload: { result: items = [] as TableView[] },
    } = action;
    items.forEach((element: TableView) => {
        dict[element.id] = element;
    });
    return dict;
};

export const processLoadedTables = (
    _state: SubscriptionDataState | SubscriptionState,
    action: BaseAction,
) => {
    const dict: { [key: string]: Table } = {};
    const {
        payload: { result: items = [] as Table[] },
    } = action;
    items.forEach((element: Table) => {
        dict[element.id] = element;
    });
    return dict;
};

export const processLoadedCategories = (
    _state: SubscriptionDataState | SubscriptionState,
    action: BaseAction,
) => {
    const dict: { [key: string]: Category } = {};
    const {
        payload: { result: items = [] as Category[] },
    } = action;
    items.forEach((element: Category) => {
        dict[element.id] = element;
    });
    return dict;
};

export const processLoadedLists = (
    _state: SubscriptionDataState | SubscriptionState,
    action: BaseAction,
) => {
    const dict: { [key: string]: List } = {};
    const {
        payload: { result: items = [] as List[] },
    } = action;
    items.forEach((element: List) => {
        dict[element.id] = element;
    });
    return dict;
};

export const processLoadedHeaders = (
    _state: SubscriptionDataState | SubscriptionState,
    action: BaseAction,
) => {
    let newTables: { [id: string]: Table } = {};
    let newTableViews: { [id: string]: TableView } = {};

    const dict: { [key: string]: Header } = {};
    const {
        payload: { result: items = [] as Header[] },
    } = action;
    items.forEach((element: Header) => {
        newTables = {
            ...newTables,
            [`${element.id}-${COORDINATES_ID}`]: {
                columns: Object.assign(
                    {},
                    ...Object.values(element.coordinatesTable.columns).map((x) => ({
                        [x.id]: {
                            ...x,
                            ...(x.type === 'Projection' && { type: 'list', list: GRID_COLUMN_ID }),
                        } as Column,
                    })),
                ),
                tableView: `${element.id}-${COORDINATES_ID}`,
                id: `${element.id}-${COORDINATES_ID}`,
                name: COORDINATES_LABEL,
                label: COORDINATES_LABEL,
                isvalid: true,
                intervalType: DrillHoleReferencesIntervalType?.DATA,
            } as Table,
        };

        newTableViews = {
            ...newTableViews,
            [`${element.id}-${COORDINATES_ID}`]: {
                singleTable: `${element.id}-${COORDINATES_ID}`,
                id: `${element.id}-${COORDINATES_ID}`,
                intervalType: DrillHoleReferencesIntervalType?.DATA,
                tables: {},
                name: COORDINATES_LABEL,
                label: COORDINATES_LABEL,
                tableView: `${element.id}-${COORDINATES_ID}`,
            } as any,
        };

        dict[element.id] = element;
    });
    return {
        headers: { ...(_state.headers ?? {}), ...dict },
        tables: { ..._state.tables, ...newTables },
        tableViews: { ..._state.tableViews, ...newTableViews },
    };
};

export const processLoadedHeaderTypes = (
    _state: SubscriptionDataState | SubscriptionState,
    action: BaseAction,
) => {
    const dict: { [key: string]: HeaderType } = {};
    const {
        payload: { result: items = [] as HeaderType[] },
    } = action;
    items.forEach((element: HeaderType) => {
        dict[element.id] = element;
    });
    return dict;
};

export const processLoadedSystemFilters = (
    _state: SubscriptionDataState | SubscriptionState,
    action: BaseAction,
) => {
    const dict: { [key: string]: SystemFilter } = {};
    const {
        payload: { result: items = [] as SystemFilter[] },
    } = action;
    items.forEach((element: SystemFilter) => {
        dict[element.id] = element;
    });
    return dict;
};

export const processLoadedFilterOperators = (
    _state: SubscriptionDataState | SubscriptionState,
    action: BaseAction,
) => {
    const dict: { [key: string]: FilterOperator } = {};
    const {
        payload: { result: items = [] as FilterOperator[] },
    } = action;
    sortByIndex(items, 'order');
    items.forEach((element: FilterOperator) => {
        dict[element.type] = element;
    });

    dict[FILTER_TYPES.LIST_OBJECT] = {
        id: uuidv4(),
        type: FILTER_TYPES.LIST_OBJECT,
        hasOperator: true,
        operators: [
            {
                name: 'one_of',
                label: 'Is one of',
                numValues: 0,
            },
            {
                name: 'has_values',
                label: 'Has values',
                numValues: 0,
            },
            {
                name: 'empty',
                label: 'Is empty',
                numValues: 0,
            },
        ],
    };

    dict[FILTER_TYPES.EXISTS] = {
        id: uuidv4(),
        type: FILTER_TYPES.EXISTS,
        hasOperator: true,
        operators: [
            {
                name: 'has_values',
                label: 'Has values',
                numValues: 0,
            },
            {
                name: 'empty',
                label: 'Is empty',
                numValues: 0,
            },
        ],
    };

    return dict;
};

export const processLoadedDefaultPermissions = (
    _state: SubscriptionDataState | SubscriptionState,
    action: BaseAction,
) => {
    const dict: { [key: string]: DefaultPermission } = {};
    const {
        payload: { result: items = [] as DefaultPermission[] },
    } = action;
    sortByIndex(items, 'index');
    items.forEach((element: DefaultPermission) => {
        dict[element.id] = element;
    });
    return dict;
};

export const processLoadedCoordinates = (
    _state: SubscriptionDataState | SubscriptionState,
    action: BaseAction,
) => {
    const newList: List = {
        id: GRID_COLUMN_ID,
        usedIn: {},
        primaryColumn: 'Value',
        columns: {
            Value: {
                name: 'Value',
                index: 0,
                id: 'Value',
                type: 'text',
            },
        },
        published: false,
        ismx: false,
        isvalid: true,
        inuseColumn: 'inuse',
        category: '',
        name: 'Grids',
        updatedAt: { date: 0 },
        updatedBy: '',
        rows: {},
    };

    const dict: { [key: string]: GridEntry } = {};
    const {
        payload: { result: items = [] as GridEntry[] },
    } = action;
    items.forEach((element: GridEntry, index: number) => {
        newList.rows = {
            ...newList.rows,
            [element.id]: {
                id: element.id,
                values: {
                    Value: element.name,
                    inuse: true,
                },
                rank: index,
            },
        };

        dict[element.id] = element;
    });

    return { coordinates: dict, lists: { ..._state.lists, [GRID_COLUMN_ID]: newList } };
};

export const processLoadedActivityGroups = (_state: SubscriptionState, action: BaseAction) => {
    const dict: { [key: string]: ActivityGroupMap } = {};
    const {
        payload: { result: items = [] as ActivityGroupMap[] },
    } = action;
    items.forEach((element: ActivityGroupMap) => {
        dict[element.id] = element;
    });
    return dict;
};

export const processLoadedColours = (_state: SubscriptionState, action: BaseAction) => {
    const {
        payload: {
            result: { mxColours = [] as MXColour[], customColours = [] as CustomColour[] },
        },
    } = action;

    return {
        mxColours: Object.assign({}, ...mxColours.map((x: MXColour) => ({ [x.id]: x }))),
        customColours: Object.assign(
            {},
            ...customColours.map((x: CustomColour) => ({ [x.id]: x })),
        ),
    };
};

export const processLoadedSampleDispatchHeaders = (
    _state: SubscriptionDataState | SubscriptionState,
    action: BaseAction,
) => {
    const dict: { [key: string]: SampleDispatchHeader } = {};
    const {
        payload: { result: items = [] as SampleDispatchHeader[] },
    } = action;
    items.forEach((element: SampleDispatchHeader) => {
        dict[element.id] = element;
    });
    return { sampleDispatchHeaders: dict };
};

export const processLoadedSampleWorkflows = (
    _state: SubscriptionDataState | SubscriptionState,
    action: BaseAction,
) => {
    const dict: { [key: string]: SampleWorkflow } = {};
    const {
        payload: { result: items = [] as SampleWorkflow[] },
    } = action;
    items.forEach((element: SampleWorkflow) => {
        dict[element.id] = element;
    });
    return { sampleWorkflows: dict };
};

export const processLoadedSampleResultsRankedUnits = (
    _state: SubscriptionState,
    action: BaseAction,
) => {
    const {
        payload: {
            result: { units = [] },
        },
    } = action;

    return {
        units,
    };
};

export const updateObjectForAction = (
    subState: SubscriptionDataState,
    processedResult: any,
    actionName: string,
) => {
    switch (actionName) {
        case SubscriptionActionNames.loadAccountInformation:
            // eslint-disable-next-line no-param-reassign
            subState.account = processedResult.account;
            // eslint-disable-next-line no-param-reassign
            subState.users = processedResult.usersMap;
            break;
        case SubscriptionActionNames.loadAccountActivities:
            // eslint-disable-next-line no-param-reassign
            subState.activities = processedResult;
            break;
        case SubscriptionActionNames.loadTableViews:
            // eslint-disable-next-line no-param-reassign
            subState.tableViews = processedResult;
            break;
        case SubscriptionActionNames.loadTables:
            // eslint-disable-next-line no-param-reassign
            subState.tables = processedResult;
            break;
        case SubscriptionActionNames.loadTableCategories:
            // eslint-disable-next-line no-param-reassign
            subState.tableCategories = processedResult;
            break;
        case SubscriptionActionNames.loadLists:
            // eslint-disable-next-line no-param-reassign
            subState.lists = processedResult;
            break;
        case SubscriptionActionNames.loadListCategories:
            // eslint-disable-next-line no-param-reassign
            subState.listCategories = processedResult;
            break;
        case SubscriptionActionNames.loadHeaders:
            /* eslint-disable no-param-reassign */
            subState.headers = processedResult.headers;
            subState.tableViews = processedResult.tableViews;
            subState.tables = processedResult.tables;
            /* eslint-enable no-param-reassign */
            break;
        case SubscriptionActionNames.loadHeaderCategories:
            // eslint-disable-next-line no-param-reassign
            subState.headerCategories = processedResult;
            break;
        case SubscriptionActionNames.loadHeaderTypes:
            // eslint-disable-next-line no-param-reassign
            subState.headerTypes = processedResult;
            break;
        case SubscriptionActionNames.loadSystemFilters:
            // eslint-disable-next-line no-param-reassign
            subState.systemFilters = processedResult;
            break;
        case SubscriptionActionNames.loadFilterOperators:
            // eslint-disable-next-line no-param-reassign
            subState.filterOperators = processedResult;
            break;
        case SubscriptionActionNames.loadDefaultPermissions:
            // eslint-disable-next-line no-param-reassign
            subState.defaultPermissions = processedResult;
            break;
        case SubscriptionActionNames.loadCoordinates:
            /* eslint-disable no-param-reassign */
            subState.coordinates = processedResult.coordinates;
            subState.lists = processedResult.lists;
            /* eslint-enable no-param-reassign */
            break;
        case SubscriptionActionNames.loadActivityGroups:
            // eslint-disable-next-line no-param-reassign
            subState.activityGroups = processedResult;
            break;
        case SubscriptionActionNames.loadActivityCategories:
            // eslint-disable-next-line no-param-reassign
            subState.activityCategories = processedResult;
            break;
        case SubscriptionActionNames.loadColours:
            /* eslint-disable no-param-reassign */
            subState.customColours = processedResult.customColours;
            subState.mxColours = processedResult.mxColours;
            /* eslint-enable no-param-reassign */
            break;
        case SubscriptionActionNames.loadSampleResultsRankedUnits:
            /* eslint-disable no-param-reassign */
            subState.units = processedResult.units;
            break;
        case SubscriptionActionNames.loadSampleDispatchHeaders:
            // eslint-disable-next-line no-param-reassign
            subState.sampleDispatchHeaders = processedResult.sampleDispatchHeaders;
            break;
        case SubscriptionActionNames.loadSampleWorkflows:
            // eslint-disable-next-line no-param-reassign
            subState.sampleWorkflows = processedResult.sampleWorkflows;
            break;
        default:
            break;
    }
};
