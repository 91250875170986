import { Grid, Skeleton } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { userState } from 'state-domains/domain/user';

import { getPreferences } from 'src/utilities';

import { skeletonRowHeightStyles, useStyles } from './DataGridSkeleton.styles';

export function DataGridSkeleton() {
    const {
        selectors: { currentUserId: currentUserIdSelector },
    } = userState;
    const currentUserId = useSelector(currentUserIdSelector);
    const [rowHeight] = useState(getPreferences(currentUserId).rowHeight);

    const { classes } = useStyles();
    const { classes: rowHeightClasses } = skeletonRowHeightStyles(Number(rowHeight));

    return (
        <Grid className={classes.dataGridContainer} automation-id="data-grid-skeleton-base">
            <Grid container className={classes.DataGridContainer}>
                <Grid className={classes.DataGridHeader} />
                <Grid className={classes.DataGridContentContainer}>
                    {Array(100)
                        .fill(true)
                        .map((_, idx) => (
                            <Skeleton
                                key={uuidv4()}
                                variant="rectangular"
                                animation="wave"
                                className={`${classes.DataGridItem} ${rowHeightClasses.rowHeights}`}
                                sx={{ backgroundColor: `${idx % 2 === 0 ? '#F9F9F9' : '#FFFFFF'}` }} // Alternates row colour
                            />
                        ))}
                </Grid>
            </Grid>
        </Grid>
    );
}
