import * as React from 'react';

import { DIMENSION_LIST_EDITOR, DIMENSION_PREFERENCE_DENOMINATIONS } from 'state-domains/constants';

export interface GenericWithProps<T> {
    (baseComponent: React.ReactNode): React.ComponentType<T>;
}

export interface HeaderFieldInfo {
    id: string;
    name: string;
    label: string;
    type: string;
    dynamicFilter: boolean;
    values?: HeaderFieldInfoValues[];
    operatorLabelOverrides?: { [id: string]: string };
}

export interface HeaderFieldInfoValues {
    id: string;
    value: any;
    index: number;
}

export enum ReadOnlyReasons {
    REVIEWER = 'REVIEWER',
    ACTIVITY_GROUP = 'ACTIVITY_GROUP',
    INVALID_DRILLHOLE_STATE = 'INVALID_DRILLHOLE_STATE',
    XRF_TABLE = 'XRF_TABLE',
    ASSIGNED_USER = 'ASSIGNED_USER',
}

export interface Preferences {
    rowHeight: string;
    listEditor: string;
    listColourWidth: string;
    showRowNumbers: boolean;
    rowsPerPage?: number;
    pasteOptions: string;
}

export enum PASTE_OPTIONS {
    FAIL_FAST = 'failFast',
    PASTE_AS_MUCH_AS_POSSIBLE = 'pasteAsMuchAsPossible',
}

export const DEFAULT_PREFERENCES: Preferences = {
    rowHeight: DIMENSION_PREFERENCE_DENOMINATIONS.DEFAULT.toString(),
    listEditor: DIMENSION_LIST_EDITOR.DEFAULT.toString(),
    listColourWidth: DIMENSION_PREFERENCE_DENOMINATIONS.DEFAULT.toString(),
    showRowNumbers: true,
    pasteOptions: PASTE_OPTIONS.FAIL_FAST,
    // rowsPerPage defaults are governed by the PaginationControls
};
