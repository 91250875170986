import * as React from 'react';
import { useDispatch } from 'react-redux';
import useWebSocket from 'react-use-websocket';

import { jobState } from 'state-domains/domain';
import { getClassicMXDepositUrl, isValidSecureUrl } from 'state-domains/utils';

import { setSessionId } from 'src/utilities';

export interface WebsocketMessage {
    event: string;
    notification_id: string;
    user_id: string;
}

const createWebSocketUrl = (): string => {
    const classicMXDepositBaseUrl = getClassicMXDepositUrl();
    if (!classicMXDepositBaseUrl || !isValidSecureUrl(classicMXDepositBaseUrl)) {
        return '';
    }

    const baseUrl = new URL(classicMXDepositBaseUrl);
    const websocketUrl = new URL('/websocket', baseUrl);
    websocketUrl.protocol = 'wss:';

    return websocketUrl.toString();
};

const MAX_RECONNECT_ATTEMPTS = 10;
const RECONNECT_INTERVALS = 5000;

export const WebSocketManager = () => {
    const dispatch = useDispatch();

    const {
        actions: { updateJob, completeJob },
    } = jobState;

    const eventMap: { [id: string]: ((data: WebsocketMessage) => void)[] } = React.useMemo(
        () => ({
            jobUpdated: [
                (data: WebsocketMessage) => {
                    updateJob(data.event)(dispatch);
                },
            ],
            jobCompleted: [
                (data: WebsocketMessage) => {
                    completeJob(data.event);
                },
            ],
        }),
        [],
    );

    const websocketUrl = React.useMemo(createWebSocketUrl, []);
    useWebSocket(websocketUrl, {
        onOpen: setSessionId,
        shouldReconnect: () => true,
        reconnectAttempts: MAX_RECONNECT_ATTEMPTS,
        reconnectInterval: RECONNECT_INTERVALS,
        onMessage: (event: MessageEvent) => {
            try {
                const eventData = JSON.parse(event.data);

                if (eventData.notificationType in eventMap) {
                    eventMap[eventData.notificationType].forEach(
                        (callback: (data: any) => void) => {
                            callback(eventData.notificationData);
                        },
                    );
                }
            } catch (error) {
                console.error('Failed to parse WebSocket message:', error);
            }
        },
    });
    return <></>;
};
