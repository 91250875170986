import { isEmpty } from 'lodash-es';
import { Dispatch } from 'redux';
import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import {
    LITHOLOGY_TABLE_ID,
    SAMPLE_TABLE_IDS,
    SPECIAL_TABLE_TYPES,
    STRUCTURE_TABLES,
    SURVEY_TABLE_ID,
    XRF_TABLE,
} from 'state-domains/constants';
import {
    Operations,
    Table,
    TableView,
    UserType,
    UserWithType,
} from 'state-domains/domain/subscription';
import { CREATE_UPDATE_DELETE_COLLECTION_ITEM_PROJECT_STATE } from 'state-domains/types/actionTypes';
import {
    convertToCamel,
    convertToSnake,
    sendDeleteRequestWithXSRFToken,
    sendRequestWithXSRFToken,
} from 'state-domains/utils';
import { getState, typeComplete } from 'state-domains/utils/redux';

import { reindexDataToObject } from 'src/components/Configuration/ActivitiesProjects/Projects/ProjectsMasterDetail/ProjectsDetail/GenericDragAndDrop/GenericDragAndDrop';
import { UserPortalState } from 'src/state';

import { Activity, Group, Project } from '../project/types';
import { AssignUnassignObjectType } from './types';

export const generateImportErrorText = (errors: { line: number; message: string }[]) => {
    const strArray = errors.map((error) =>
        error.line ? `line ${error.line}: ${error.message}` : error.message,
    );
    return strArray.join('\n');
};

export const downloadFileFromText = (fileInfo: { filename: string; text: string }[]) => {
    if (!fileInfo.length) {
        return;
    }

    const element = document.createElement('a');
    element.style.display = 'none';
    document.body.appendChild(element);

    for (const file of fileInfo) {
        element.setAttribute(
            'href',
            `data:text/plain;charset=utf-8,${encodeURIComponent(file.text)}`,
        );
        element.setAttribute('download', file.filename);

        element.click();
    }

    document.body.removeChild(element);
};

export const generateTablesImportErrorText = (
    errors: { [tableName: string]: { [error: string]: string[] } },
    success: string[],
) => {
    const successArray = (success ?? []).map((error: any) => `${error}: Successfully imported\n`);

    const errArray = Object.entries(errors).map((error: any) =>
        importErrorParser(error[1], `${error[0]}: Import failed\n`, 1),
    );
    if (successArray.length > 0) {
        return `${successArray.join('\n')}\n${errArray.join('\n')}`;
    }

    return errArray.join('\n');
};

export const generateListsImportErrorText = (errors: {
    [name: string]: { [error: string]: string[] };
}) => {
    const errArray = Object.entries(errors).map((error: any) =>
        importErrorParser(error[1], `${error[0]}: Import failed\n`, 1),
    );

    return errArray.join('\n');
};

export const generateBulkImportPayload = async (collection: any, payloads: any) => {
    const arr: any[] = [];
    for (let i = 0; i < collection.length; i += 1) {
        /* eslint-disable no-await-in-loop */
        const data = await bulkImportParser(collection[i], payloads, i);
        arr.push(data);
    }

    return arr;
};

const bulkImportParser = async (x: any, payloads: any, idx: number) => {
    let result = {};
    if (x?.ok === false) {
        const data = await x.json();
        const errorString = importErrorParser(Object.entries(data)[0][1], '', 0);
        result = {
            errors: [{ message: errorString }],
            infos: [],
            success: false,
            verifiedWarnings: true,
            list: null,
            id: payloads[idx].id,
        };
    }

    return result;
};

const importErrorParser = (obj: any, errorMessage: string, depth: number) => {
    Object.entries(obj).forEach((item: any) => {
        if (item[1].constructor === Array) {
            const arrayVal = `${item[0]}: ${item[1]}\n`;
            // eslint-disable-next-line no-param-reassign
            errorMessage += arrayVal.padStart(arrayVal.length + depth, '\t');
        } else {
            const newVal = `${item[0]}:\n`;
            // eslint-disable-next-line no-param-reassign
            errorMessage += newVal.padStart(newVal.length + depth, '\t');
            // eslint-disable-next-line no-param-reassign
            errorMessage = importErrorParser(item[1], errorMessage, depth + 1);
        }
    });
    return errorMessage;
};

export const dispatchProjectCollections = (
    item: Project,
    dispatch: Dispatch,
    projectFileGroup: any = null,
) => {
    const allState: UserPortalState = getState() as UserPortalState;
    const userId = allState.user.id;

    dispatch({
        type: typeComplete(CREATE_UPDATE_DELETE_COLLECTION_ITEM_PROJECT_STATE),
        payload: {
            id: item.id,
            item,
            type: 'PUT',
            collectionName: 'allProjects',
        },
    });

    if (projectFileGroup) {
        dispatch({
            type: typeComplete(CREATE_UPDATE_DELETE_COLLECTION_ITEM_PROJECT_STATE),
            payload: {
                id: projectFileGroup.id,
                item: projectFileGroup,
                type: 'PUT',
                collectionName: 'projectFileGroups',
            },
        });
    }

    const userInProject = getUsersForObject(
        'project',
        item,
        allState.subscription.users,
        { id: '' },
        { id: '' },
    ).projectAllUsers.find((x) => x.id === userId);

    dispatch({
        type: typeComplete(CREATE_UPDATE_DELETE_COLLECTION_ITEM_PROJECT_STATE),
        payload: {
            id: item.id,
            item,
            type: userInProject ? 'PUT' : 'DELETE',
            collectionName: 'projects',
        },
    });
};

export const updateActivitiesInProjectCollections = (
    projectId: string,
    items: Activity[],
    dispatch: Dispatch,
    isDelete = false,
) => {
    const allState: UserPortalState = getState() as UserPortalState;
    const project = allState.project.allProjects[projectId];
    const updatedProject = { ...project };
    items.forEach((x) => {
        if (isDelete) {
            delete updatedProject.activities[x.id];
        } else {
            updatedProject.activities[x.id] = x;
        }
    });
    const sortedProjectActivities = reindexDataToObject(Object.values(updatedProject.activities));
    dispatchProjectCollections(
        { ...updatedProject, activities: sortedProjectActivities },
        dispatch,
    );
};

export const getChildTables = (tableView: TableView, tables: { [key: string]: Table }) => {
    if (!tableView.singleTable) {
        return [];
    }
    return Object.values(tables).filter((t) => t.parent === tableView.id);
};
export const makeGroupIdentifier = (activityId: string, groupId: string) =>
    `${activityId}-${groupId}`;
const isUserInActive = (user: UserWithType) =>
    isEmpty(user?.subscriptions) || user?.type === 'inactive';
const getUserObject = (user: UserWithType, accountId?: string) => {
    const name = user.profile?.name ?? user.email ?? '';
    let accountRole = '';
    Object.values(user.subscriptions ?? {}).forEach((subscription) => {
        if (subscription.account === accountId && accountRole !== UserType.Admin) {
            accountRole = subscription.role;
        }
    });
    return {
        ...(user?.profile ?? {}),
        id: user?.id ?? '',
        label: name,
        name,
        subLabel: user?.email ?? '',
        email: user?.email ?? '',
        type: user?.type,
        accountRole,
        isInActive: isUserInActive(user),
    };
};
export type UserObjectType = ReturnType<typeof getUserObject> & { fullAccess?: boolean };
export const getUsersForObject = (
    objectType: AssignUnassignObjectType,
    project: Project,
    allUsers: { [p: string]: UserWithType },
    activity: { id: string },
    group: { id: string },
    accountId?: string,
) => {
    if (isEmpty(project)) {
        return {
            allAvailableUsers: [],
            selectedUsersForObject: [],
            projectAllUsers: [],
            usersMap: {},
        };
    }
    const usersMap: { [id: string]: { [key: string]: UserObjectType } } = {
        [project.id]: {} as {
            [key: string]: UserObjectType;
        },
    };
    const allProjectUsersMap: { [key: string]: UserObjectType } = {} as {
        [key: string]: UserObjectType;
    };
    Object.assign(
        usersMap[project.id],
        ...Object.keys(project.users ?? {}).map((x) => {
            if (allUsers[x]) {
                const u = getUserObject(allUsers[x], accountId);
                allProjectUsersMap[x] = u;
                return { [x]: { ...u, fullAccess: true } };
            }
            return {};
        }),
    );

    Object.values(project.activities ?? {}).forEach((x) => {
        usersMap[x.id] = {} as { [key: string]: UserObjectType };
        Object.assign(
            usersMap[x.id],
            ...Object.keys(x.users ?? {})
                .filter((y) => !(y in (project.users ?? {})))
                .map((y: string) => {
                    if (allUsers[y]) {
                        const u = getUserObject(allUsers[y], accountId);
                        allProjectUsersMap[y] = u;
                        return {
                            [y]: {
                                ...u,
                                fullAccess: project.activities[x.id].users[y]?.fullAccess ?? true,
                            },
                        };
                    }
                    return {};
                }),
        );

        Object.values(x.groups ?? {}).forEach((y: Group) => {
            usersMap[makeGroupIdentifier(x.id, y.id)] = {} as { [key: string]: UserObjectType };
            Object.assign(
                usersMap[makeGroupIdentifier(x.id, y.id)],
                ...Object.keys(y.users ?? {})
                    .filter(
                        (z) =>
                            !(z in (project.users ?? {})) &&
                            (!(z in (project.activities?.[x?.id ?? '']?.users ?? {})) ||
                                ('fullAccess' in
                                    (project.activities?.[x?.id ?? '']?.users[z] ?? {}) &&
                                    !project.activities[x.id].users[z].fullAccess)),
                    )
                    .map((z) => {
                        if (allUsers[z]) {
                            const u = getUserObject(allUsers[z], accountId);
                            allProjectUsersMap[z] = u;
                            return { [z]: { ...u, fullAccess: true } };
                        }
                        return {};
                    }),
            );
        });
    });
    const projectUsers = Object.values(usersMap[project.id] ?? {});
    const activityUsers = Object.values(usersMap[activity.id] ?? {}).filter((x) => x.fullAccess);
    const groupUsers = Object.values(usersMap[makeGroupIdentifier(activity.id, group.id)] ?? {});
    let users = [] as UserObjectType[];
    if (objectType === 'project') {
        users = projectUsers;
    } else if (objectType === 'activity' && activity?.id) {
        users = [...activityUsers, ...projectUsers];
    } else if (objectType === 'group') {
        users = [...groupUsers, ...activityUsers, ...projectUsers];
    }

    const filteredUsers = Object.values(allUsers)
        .filter((x) => !isUserInActive(x))
        .map((user: UserWithType) => getUserObject(user));
    filteredUsers.sort((a, b) => a?.name.localeCompare(b?.name ?? '') ?? 0);
    return {
        allAvailableUsers: filteredUsers,
        selectedUsersForObject: users,
        projectAllUsers: Object.values(allProjectUsersMap).sort(
            (a, b) => a?.name.localeCompare(b?.name ?? '') ?? 0,
        ),
        usersMap,
    };
};

export function createOperationObservable<T, T2 = any>(
    operation: Operations,
    urlFn: Function,
    dataFn?: Function,
    id = '',
    payload: any = {},
    responseKey = '',
    additionalData: any = {},
): Observable<{ data: T } & T2> {
    let obs;
    switch (operation) {
        case 'add':
            obs = sendRequestWithXSRFToken(urlFn(), convertToSnake(payload), 'POST');
            break;
        case 'edit':
            obs = sendRequestWithXSRFToken(urlFn(id), convertToSnake(payload), 'PUT');
            break;
        case 'remove':
            obs = sendDeleteRequestWithXSRFToken(urlFn(id));
            break;
    }
    return obs.pipe(
        switchMap(({ response }: AjaxResponse<any>) => {
            let res = convertToCamel<T>(responseKey ? response[responseKey] : response);

            if (dataFn) {
                res = dataFn(res);
            }

            return observableOf({ data: res, ...additionalData });
        }),
    );
}

export const findSpecialType = (collection: any[], tableId: string) => {
    if (tableId === LITHOLOGY_TABLE_ID) {
        // eslint-disable-next-line no-param-reassign
        collection.push(SPECIAL_TABLE_TYPES.LITHOLOGY);
    } else if (SAMPLE_TABLE_IDS.includes(tableId)) {
        // eslint-disable-next-line no-param-reassign
        collection.push(SPECIAL_TABLE_TYPES.SAMPLES);
    } else if (tableId === SURVEY_TABLE_ID) {
        // eslint-disable-next-line no-param-reassign
        collection.push(SPECIAL_TABLE_TYPES.SURVEY);
    } else if (tableId === XRF_TABLE) {
        // eslint-disable-next-line no-param-reassign
        collection.push(SPECIAL_TABLE_TYPES.XRF);
    } else if (STRUCTURE_TABLES.includes(tableId)) {
        // eslint-disable-next-line no-param-reassign
        collection.push(SPECIAL_TABLE_TYPES.STRUCTURE);
    }
};

const findParentAndChildrenTables = (
    types: any[],
    collectionToAdd: any[],
    table: Table,
    tables: { [id: string]: Table },
) => {
    if (table.parent) {
        findSpecialType(types, table.parent);

        // eslint-disable-next-line no-param-reassign
        collectionToAdd.push(table.parent);
    } else {
        // eslint-disable-next-line no-param-reassign
        collectionToAdd.push(
            ...Object.values(tables)
                .filter((x) => x.parent === table.id)
                .map((x) => x.id),
        );
    }
};

export const determineSpecialTable = (tableView: TableView, tables: { [id: string]: Table }) => {
    const types: any[] = [];
    const relatedTables: any[] = [tableView.id];

    const table = tables[tableView.id];

    if (!!tableView.singleTable && !table) {
        return null;
    }

    findSpecialType(types, tableView.id);

    if (tableView.lithologyTableView) {
        types.push(SPECIAL_TABLE_TYPES.LITHOLOGY);
    }

    if (!tableView.singleTable) {
        Object.keys(tableView.tables ?? {}).forEach((y) => {
            const tableViewTable = tables[y];
            if (tableViewTable) {
                relatedTables.push(y);

                findSpecialType(types, y);
                findParentAndChildrenTables(types, relatedTables, tableViewTable, tables);
            }
        });
    } else {
        findParentAndChildrenTables(types, relatedTables, table, tables);
    }

    return {
        types: [...new Set(types.sort((a: string, b: string) => a.localeCompare(b)))],
        relatedTables,
    };
};

export const sortAndReIndex = (item: any, sourceIndex?: number, destinationIndex?: number) => {
    const fields = Object.values(item ?? {}).sort((a: any, b: any) => a.index - b.index);

    if ((sourceIndex || sourceIndex === 0) && (destinationIndex || destinationIndex === 0)) {
        const field = fields[sourceIndex];
        fields.splice(sourceIndex, 1);
        fields.splice(destinationIndex, 0, ...[field]);
    }

    const updatedFields: any[] = fields.map((x: any, idx) => ({ ...x, index: idx }));
    const payload = Object.assign({}, ...updatedFields.map((x) => ({ [x.id]: x.index })));

    return { updatedFields, payload };
};
