import { LOAD_STATUS_PENDING, CLIENT_SIDE_PAGINATION_LIMIT } from '../../../constants';
import { BaseAction } from '../../../types';
import {
    CLEAR_JOB_LIST,
    LIST_JOBS,
    REFRESH_STATE,
    SET_JOB_SEARCH_TERM,
    CREATE_OR_UPDATE_JOB,
} from '../../../types/actionTypes';
import {
    completeReducer,
    failureReducer,
    mappedReducer,
    pendingReducer,
    typeComplete,
    typeFail,
    typePending,
} from '../../../utils';
import { Job, JobError, JobState } from '../types';

export const INITIAL_STATE: JobState = {
    status: LOAD_STATUS_PENDING,
    error: null,
    jobs: {},
    searchTerm: '',
    offset: 0,
    limit: CLIENT_SIDE_PAGINATION_LIMIT,
};

function setJobSearchTermReducer(
    state: JobState,
    action: BaseAction<{ searchTerm: string; offset: number; limit: number }>,
): JobState {
    const { searchTerm, offset, limit } = action.payload;
    return completeReducer({
        ...state,
        searchTerm,
        offset,
        limit,
    });
}

const createOrUpdateJob = (state: JobState, action: BaseAction<Job>) => {
    const { id } = action.payload;
    if (!id) {
        return state;
    }
    const job = state.jobs[id] ?? {};
    return {
        ...state,
        jobs: {
            ...state.jobs,
            [id]: {
                ...job,
                ...action.payload,
            },
        },
    };
};

const listJobsPendingReducer = (state: JobState): JobState => pendingReducer({ ...state });

const listJobsSuccessReducer = (state: JobState, actions: BaseAction) =>
    completeReducer({
        ...state,
        jobs: Object.fromEntries(actions.payload.map((job: Job) => [job.id, job])),
    });

function listJobsFailReducer(state: JobState, action: BaseAction<JobError>) {
    const { error } = action.payload;
    return failureReducer({ ...state, error });
}
const resetJobStateReducer = (_state: JobState, _action: BaseAction) => ({ ...INITIAL_STATE });

const clearJobListReducer = (_state: JobState, _action: BaseAction) => ({ ...INITIAL_STATE });

export const reducer = mappedReducer(INITIAL_STATE, {
    [typePending(LIST_JOBS)]: listJobsPendingReducer,
    [typeComplete(LIST_JOBS)]: listJobsSuccessReducer,
    [typeFail(LIST_JOBS)]: listJobsFailReducer,
    [typeComplete(SET_JOB_SEARCH_TERM)]: setJobSearchTermReducer,
    [typeComplete(CLEAR_JOB_LIST)]: clearJobListReducer,
    [CREATE_OR_UPDATE_JOB]: createOrUpdateJob,
    [REFRESH_STATE]: resetJobStateReducer,
});
