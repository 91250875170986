import { Observable, of as observableOf } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { buildUserAccountsUrl } from '../../../network/urls';
import { convertToCamel, sendRequestWithXSRFToken } from '../../../utils';

export const loadUserAccounts = (): Observable<any> => {
    const url = buildUserAccountsUrl();
    return sendRequestWithXSRFToken(url, {}).pipe(
        switchMap(({ response }) => {
            const convertedResponse = convertToCamel<any>(response);
            return observableOf(convertedResponse);
        }),
    );
};
