import { Observable, of as observableOf } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { buildProjectThumbnailFileGroupUrl } from '../../../network/urls';
import { convertToCamel, sendFileUploadRequestWithXSRFTokenCatchErrors } from '../../../utils';

export const uploadProjectThumbnail = (groupId: string, fileObject: any): Observable<any> => {
    const url = buildProjectThumbnailFileGroupUrl(groupId);
    const file = { file: fileObject };
    return sendFileUploadRequestWithXSRFTokenCatchErrors(url, file).pipe(
        switchMap((result: any) => {
            const converted = convertToCamel(result);
            return observableOf(converted);
        }),
    );
};
