import IntervalTree from '@flatten-js/interval-tree';
import { cloneDeep } from 'lodash-es';
import store from 'store';

import {
    DataFileGroupResponse,
    FileObject,
    FilesForGroup,
    HeaderFileGroupResponse,
    TableView,
} from 'state-domains/domain';

import { getPreferences } from 'src/utilities';

import {
    COORDINATES_ID,
    LOAD_STATUS_COMPLETE,
    LOAD_STATUS_PENDING,
    LOAD_STATUS_STALE,
    OUT_OF_ORDER,
    PARENT_SKIP_LITHOLOGY_ROW,
    RANKED_RESULTS,
    ROW_NUMBER,
    SAMPLE_RESULTS,
    SAMPLE_TYPE_COL_ID,
    SKIP_LITHOLOGY_ROW,
} from '../../../constants';
import { BaseAction } from '../../../types';
import {
    LOAD_DRILLHOLES,
    SELECT_COLLARS,
    SELECT_COLLARS_BY_ID,
    LOAD_DRILLHOLE,
    LOAD_ROW_REFERENCES,
    SAVE_ROW_REFERENCES,
    FILL_DOWN_SAVE_ROW_REFERENCES,
    SET_CURRENT_DRILLHOLE,
    CLEAR_ROW_REFERENCE_RESPONSE,
    SAVE_DRILLHOLE,
    ADD_DATA_FILE_GROUP,
    UPLOAD_FILE,
    GET_FILES_FOR_GROUP,
    CLEAR_FILES_STATE,
    CLEAR_FILES_FOR_GROUP_LENGTHS_STATE,
    DELETE_FILE,
    CLEAR_SAVE_STATE,
    DOWNLOAD_FILE,
    CLEAR_DOWNLOADED_FILE,
    DELETE_ROW_REFERENCES,
    DUMP_TABLE_DATA,
    CLEAR_DUMP_TABLE_DATA,
    CREATE_ROWS,
    COPY_INTERVALS,
    ADD_DRILLHOLE,
    RE_INDEX,
    CLEAR_ADD_DRILL_HOLE_STATE,
    DELETE_DRILLHOLE,
    LOAD_EMPTY_FILE_GROUPS,
    LOAD_SAMPLE,
    CLEAR_EMPTY_FILE_GROUPS,
    SAVE_SPLIT_ROW_ROW_REFERENCES,
    LOAD_SAMPLE_RESULTS,
    LOAD_LAB_CERTIFICATE_INFO,
    LOAD_SAMPLE_ROW_REFERENCES,
    CLONE_DRILHOLE,
    CLEAR_CLONE_DRILL_HOLE_STATE,
    CLEAR_SAVE_ROW_REFERENCE_STATE,
    CLEAR_DELETE_DRILL_HOLE_STATE,
    UNASSIGN_USER_FROM_DRILLHOLE,
    CLEAR_UNASSIGN_USER_FROM_DHOLE_STATE,
    LOAD_COORDINATES_TABLE,
    EXPORT_COLLAR,
    CUSTOM_EXPORT,
    GET_EXPORT_TEMPLATES,
    GET_CUSTOM_EXPORTS,
    CLEAR_EXPORT_COLLAR_STATE,
    SELECT_ALL_COLLARS,
    UNSELECT_COLLARS,
    CLEAR_ALL_COLLARS,
    REFRESH_STATE,
    MERGE_ROW_REFERENCES,
    TOGGLE_SPAN,
    APPLY_EXTERNAL_FILTER,
    FILTER_APPLIED,
    COPY_CELLS,
    COPY_COORDINATE_CELLS,
    LOAD_DRILLHOLES_WITH_COUNT,
    SET_COLLAR_SEARCH_TERM,
} from '../../../types/actionTypes';
import {
    completeReducer,
    failureReducer,
    mappedReducer,
    pendingReducer,
    typeComplete,
    typeFail,
    typePending,
} from '../../../utils';
import {
    Drillhole,
    DrillholeState,
    CurrentDrillholeState,
    RowReference,
    Sample,
    LoadRowReferenceState,
    LabCertificateInfo,
} from '../types';
import {
    addResultToStateCollection,
    attachTempResultData,
    constructTableColumns,
    constructTableRows,
    convertRowToRowReference,
    createDictionary,
    linkLithologyToRowReferences,
    modifyColumnsForLinkedToLithology,
    processLoadedRowReferences,
    adjustListPointers,
    createRowReferencesObjectForNewRow,
    compareIntervalsWithoutLength,
    removeIntervalIfPresent,
    addRowNumberColumn,
    findSpecialTableByIdentifier,
    combineNewRows,
} from '../utils';

export const INITIAL_CURRENT_DRILLHOLE_STATE: CurrentDrillholeState = {
    status: LOAD_STATUS_COMPLETE,
    error: null,
    id: '',
};

export const INITIAL_ROW_REFERENCE_LOAD_STATE = {
    status: LOAD_STATUS_PENDING,
    error: null,
};

export const INITIAL_DELETED_FILE_STATE = {
    status: LOAD_STATUS_COMPLETE,
    error: null,
    deletedFile: {} as FileObject,
};
export const INITIAL_FILES_FOR_GROUP = {
    status: LOAD_STATUS_COMPLETE,
    error: null,
    filesForGroup: {} as { [id: string]: FilesForGroup },
};
export const INITIAL_FILE_GROUP = {
    status: LOAD_STATUS_COMPLETE,
    error: null,
    fileGroup: {} as DataFileGroupResponse | HeaderFileGroupResponse,
};

export const INITIAL_SAVE_STATE = {
    status: LOAD_STATUS_PENDING,
    error: null,
};

// Refactor all initial states to be stale instead of pending
export const INITIAL_SAVE_STATE_STALE = {
    status: LOAD_STATUS_STALE,
    error: null,
};

export const INITIAL_DELETE_DRILL_HOLE_STATE = {
    status: LOAD_STATUS_PENDING,
    error: null,
};

export const INITIAL_ADD_NEW_DRILL_HOLE_STATE = {
    status: LOAD_STATUS_PENDING,
    error: null,
};

export const INITIAL_CLONE_DRILL_HOLE_STATE = {
    status: LOAD_STATUS_PENDING,
    error: null,
};

export const INITIAL_LAB_CERT_INFO_STATE = {
    status: LOAD_STATUS_PENDING,
    error: null,
    labCertInfo: undefined,
};

export const INITIAL_UNASSIGN_USER_STATE = {
    status: LOAD_STATUS_PENDING,
    error: null,
};

export const INITIAL_EXPORT_COLLARS_STATE = {
    status: LOAD_STATUS_PENDING,
    error: null,
};

export const INITIAL_CUSTOM_EXPORT_STATE = {
    status: LOAD_STATUS_PENDING,
    error: null,
};

export const INITIAL_EXPORT_TEMPLATES_STATE = {
    status: LOAD_STATUS_PENDING,
    error: null,
};

export const INITIAL_LOAD_COLLARS_STATE = {
    status: LOAD_STATUS_PENDING,
    error: null,
    collars: {},
};

export const INTIAL_ROW_REFERENCE_STATE = {
    modifiedRows: {
        isSequenceChanged: false,
        reconstructAllRows: false,
        isSorted: false,
        rows: [],
        redrawRows: [],
    },
    rows: {},
    excludedFillDownColumns: [],
    columns: [],
    rowReferencesLoadState: INITIAL_ROW_REFERENCE_LOAD_STATE,
    rowReferences: {},
    rowReferencesListPointers: {},
    rowReferencesIntervalTree: null,
    tableView: {} as TableView,
    samples: {},
    temporaryTables: {},
    labCertificateInfoState: INITIAL_LAB_CERT_INFO_STATE,
    lithologyIntervalTree: null,
    linkedToLithoTable: '',
    isSorted: false,
    sampleTableId: '',
    lithologyTemporaryRows: [],
    skipAllTempLithoRows: true,
    externalFilter: '',
    filterApplied: false,
};

export const INITIAL_STATE: DrillholeState = {
    status: LOAD_STATUS_PENDING,
    error: null,
    projectId: '',
    count: 0,
    searchTerm: '',
    items: INITIAL_LOAD_COLLARS_STATE,
    selectedDrillHoles: [],
    current: INITIAL_CURRENT_DRILLHOLE_STATE,
    modifiedRowReferencesState: INITIAL_SAVE_STATE,
    deleteDrillHoleState: INITIAL_DELETE_DRILL_HOLE_STATE,
    saveDrillHoleState: INITIAL_SAVE_STATE_STALE,
    addDrillHoleState: INITIAL_ADD_NEW_DRILL_HOLE_STATE,
    cloneDrillHoleState: INITIAL_CLONE_DRILL_HOLE_STATE,

    exportCollarsState: INITIAL_EXPORT_COLLARS_STATE,
    customExports: [],
    exportTemplates: [],
    exportTemplatesState: INITIAL_EXPORT_TEMPLATES_STATE,
    customExportState: INITIAL_CUSTOM_EXPORT_STATE,
    fileGroup: {
        ...INITIAL_FILE_GROUP,
        fileGroup: {} as DataFileGroupResponse | HeaderFileGroupResponse,
    },
    emptyFileGroups: {},
    uploadedFiles: [],
    deletedFile: { ...INITIAL_DELETED_FILE_STATE, deletedFile: {} as FileObject },
    filesForGroup: {
        ...INITIAL_FILES_FOR_GROUP,
        filesForGroup: {} as { [id: string]: FilesForGroup },
    },
    filesForGroupLengths: {} as { [id: string]: number },
    downloadedFile: null,
    dumpTableData: {
        status: LOAD_STATUS_PENDING,
        error: null,
        url: null,
    },
    newDrillhole: {},
    unassignUserState: INITIAL_UNASSIGN_USER_STATE,
    maxRowSpanCount: 1,
    isSelectedAll: false,
    isClearedAll: false,
    unSelectedCollars: {},
    ...INTIAL_ROW_REFERENCE_STATE,
};

const updateDrillholesInState = (state: DrillholeState, items: Drillhole[]) => {
    const dict: { [key: string]: Drillhole } = {};
    const tempSelectedDrillHoles = [...state.selectedDrillHoles];
    const isSelectedAll = state.isSelectedAll;
    const unSelectedCollars = state.unSelectedCollars;
    // Why is items sometimes not defined?
    (items ?? []).forEach((element) => {
        dict[element.id] = element;
        // Update selected drill holes from new data
        const index = tempSelectedDrillHoles.findIndex((d) => d.id === element.id);
        if (index !== -1) {
            tempSelectedDrillHoles[index] = element;
        }
        if (isSelectedAll && !(element.id in unSelectedCollars)) {
            let found = false;
            for (const drillHole of tempSelectedDrillHoles) {
                if (drillHole.id === element.id) {
                    found = true;
                    break;
                }
            }
            if (!found) {
                tempSelectedDrillHoles.push(element);
            }
        }
    });

    return { selectedDrillHoles: tempSelectedDrillHoles, collars: dict };
};

const loadDrillholesWithCountCompleteReducer = (state: DrillholeState, action: BaseAction) => {
    const { items, count } = action.payload;
    const { selectedDrillHoles, collars } = updateDrillholesInState(state, items);
    return completeReducer({
        ...state,
        items: completeReducer({ ...state.items, collars }),
        count,
        selectedDrillHoles,
    });
};

const loadDrillholeListCompleteReducer = (state: DrillholeState, action: BaseAction) => {
    const { response } = action.payload;
    const { selectedDrillHoles, collars } = updateDrillholesInState(state, response);

    return completeReducer({
        ...state,
        items: completeReducer({ ...state.items, collars }),
        selectedDrillHoles,
    });
};

const loadDrillholeListPendingReducer = (state: DrillholeState, _action: BaseAction) =>
    pendingReducer({
        ...state,
        items: pendingReducer(state.items),
    });

const loadDrillholeListFailureReducer = (state: DrillholeState, action: BaseAction) => {
    const { error } = action.payload;
    return failureReducer({ ...state, items: failureReducer({ ...state.items, error }), error });
};

const selectAllCollarsCompleteReducer = (state: DrillholeState, _action: BaseAction) => {
    const {
        items: { collars },
    } = state;
    const selectedDrillHoles = Object.values(collars);
    return completeReducer({
        ...state,
        isSelectedAll: true,
        isClearedAll: false,
        selectedDrillHoles,
        unSelectedCollars: {},
    });
};

const clearAllCollarsCompleteReducer = (state: DrillholeState, _action: BaseAction) =>
    completeReducer({
        ...state,
        isSelectedAll: false,
        isClearedAll: true,
        selectedDrillHoles: [],
        unSelectedCollars: {},
    });

const unSelectCollarsCompleteReducer = (state: DrillholeState, action: BaseAction) => {
    const collarIds = action.payload;
    const unSelectedCollars = { ...state.unSelectedCollars };
    const {
        items: { collars },
    } = state;
    const count = state.count;
    for (const id of collarIds) {
        unSelectedCollars[id] = collars[id];
    }
    const allUnSelected = Object.keys(unSelectedCollars).length === count;
    const selectedDrillHoles = state.selectedDrillHoles.filter((x) => !collarIds.includes(x.id));
    return completeReducer({
        ...state,
        unSelectedCollars: allUnSelected ? {} : unSelectedCollars,
        selectedDrillHoles: allUnSelected ? [] : selectedDrillHoles,
        ...(allUnSelected && { isClearedAll: true }),
        ...(allUnSelected && { isSelectedAll: false }),
    });
};

const selectCollarsCompleteReducer = (state: DrillholeState, action: BaseAction) => {
    const { payload } = action;
    return selectCollarsInState(state, payload);
};

const selectCollarsInState = (state: DrillholeState, payload: Drillhole[]) => {
    const unSelectedCollars = { ...state.unSelectedCollars };
    const newDrillholes = payload.filter(
        (x: Drillhole) => !!x && !state.selectedDrillHoles.map((d) => d.id).includes(x.id),
    );
    for (const collar of payload) {
        delete unSelectedCollars[collar.id];
    }
    return {
        ...state,
        unSelectedCollars,
        selectedDrillHoles: [...newDrillholes, ...state.selectedDrillHoles],
    };
};
const selectCollarsByIdCompleteReducer = (state: DrillholeState, action: BaseAction) => {
    const { payload } = action;
    const {
        items: { collars },
    } = state;
    const fullCollars = payload.map((x: string) => collars[x]);
    return selectCollarsInState(state, fullCollars);
};

const setCurrentDrillholeReducer = (state: DrillholeState, action: BaseAction) => {
    const { payload } = action;
    return {
        ...state,
        current: completeReducer({ ...state.current, id: payload }),
    };
};

const loadDrillholeCompleteReducer = (state: DrillholeState, action: BaseAction) => {
    const { payload } = action;
    const {
        items: { collars },
    } = state;
    const newItems = { ...collars };
    newItems[payload.id] = payload;
    return {
        ...state,
        items: { ...state.items, collars: newItems },
        current: completeReducer({ ...state.current, id: payload.id }),
    };
};

const loadDrillholePendingReducer = (state: DrillholeState, _action: BaseAction) => ({
    ...state,
    current: pendingReducer(state.current),
});
const loadDrillholeFailureReducer = (state: DrillholeState, action: BaseAction) => {
    const { error } = action.payload;
    return { ...state, current: failureReducer({ ...state.current, error }) };
};

const saveDrillHolePendingReducer = (state: DrillholeState, _action: BaseAction) => ({
    ...state,
    saveDrillHoleState: pendingReducer({}),
});

const saveDrillHoleCompleteReducer = (state: DrillholeState, action: BaseAction) => {
    const savedDrillHole = action.payload;
    const {
        items: { collars },
    } = state;
    const newItems = { ...collars };
    newItems[savedDrillHole.id] = { ...newItems[savedDrillHole.id], ...savedDrillHole };
    return completeReducer({
        ...state,
        items: { ...state.items, collars: newItems },
        saveDrillHoleState: completeReducer({}),
    });
};

const saveDrillHoleFailureReducer = (state: DrillholeState, action: BaseAction) => {
    const { error } = action.payload;
    return { ...state, saveDrillHoleState: failureReducer({ error }) };
};

const loadRowReferencesPendingReducer = (state: DrillholeState, _action: BaseAction) => ({
    ...state,
    rowReferencesLoadState: pendingReducer(state.rowReferencesLoadState),
});

const loadRowReferencesCompleteReducer = (state: DrillholeState, action: BaseAction) => {
    let maxRowSpanCount = state.maxRowSpanCount ?? 1;
    let lithologyTemporaryRows: string[] = [];
    const {
        payload: { response, tableView, allState, isSample, sampleTable, lithologyTable, isXRF },
    } = action;

    const loadRowReferenceState = {
        rowReferencesIntervalTree: null,
        rowReferences: {},
        rowReferencesListPointers: {},
        samples: {},
        temporaryTables: {},
        lithologyIntervalTree: null,
        linkedToLithoTable: '',
    } as LoadRowReferenceState;

    let sampleResultRowReferences: any = {};
    let sampleRowReferences: any = {};

    for (const item of response) {
        const { result, actionName, processResultFunction } = item;
        const processedResult = processResultFunction(
            state,
            result,
            allState,
            tableView,
            sampleTable,
            lithologyTable,
        );

        if (actionName === LOAD_SAMPLE_RESULTS) {
            sampleResultRowReferences = processedResult.sampleResultRowReferences;
        }

        if (actionName === LOAD_SAMPLE_ROW_REFERENCES) {
            sampleRowReferences = processedResult.sampleRowReferences;
        }

        addResultToStateCollection(actionName, processedResult, loadRowReferenceState);
    }

    const sampleTableId =
        findSpecialTableByIdentifier(tableView, allState.subscription.tables, SAMPLE_TYPE_COL_ID)
            ?.id ?? '';
    const { rowReferences, temporaryTables, samples, lithologyIntervalTree, linkedToLithoTable } =
        loadRowReferenceState;
    const combinedTables = { ...allState.subscription.tables, ...temporaryTables };
    const items = Object.values(rowReferences);
    const currentDrillHole = { ...state.current };
    const {
        items: { collars: dholeItems },
    } = state;
    const drillHole = { ...dholeItems[items[0]?.collar ? items[0]?.collar : currentDrillHole.id] };
    const preferences = getPreferences(allState?.user?.id);

    if (isSample || isXRF) {
        if (Object.keys(temporaryTables?.[SAMPLE_RESULTS]?.columns ?? {}).length === 0) {
            // eslint-disable-next-line no-param-reassign
            delete tableView.tables[RANKED_RESULTS];
        }

        items.forEach((rowReference) => {
            const sampleResultRow = sampleResultRowReferences[rowReference.index];
            const sampleRowReference = sampleRowReferences[rowReference.xrfSample ?? ''];
            if (sampleRowReference) {
                // eslint-disable-next-line no-param-reassign
                rowReference.dataRecords = {
                    ...rowReference.dataRecords,
                    [sampleTableId]: {
                        values: sampleRowReference.dataRecords,
                        rowReference: rowReference.id,
                        table: sampleTableId,
                        collar: rowReference.collar,
                        tableView: rowReference.tableView,
                        errors: { values: [] },
                        verrors: { values: [] },
                    },
                };
                // eslint-disable-next-line no-param-reassign
                rowReference.values = sampleRowReference.values;
            }

            if (sampleResultRowReferences[rowReference.index]) {
                // eslint-disable-next-line no-param-reassign
                rowReference.dataRecords = {
                    ...rowReference.dataRecords,
                    [SAMPLE_RESULTS]: {
                        ...sampleResultRow,
                        rowReference: rowReference.id,
                        table: SAMPLE_RESULTS,
                        tableView: rowReference.tableView,
                        collar: rowReference.collar,
                    },
                };
            }
        });
    }

    const key = `columnState_${allState.user.id}_${drillHole.id}`;
    const columnState = store.get(key);

    if (
        tableView.id in (columnState ?? {}) &&
        !!linkedToLithoTable !== columnState[tableView.id].linkedToLithology
    ) {
        delete columnState[tableView.id];
        store.set(key, columnState);
    }

    const columnData = constructTableColumns(
        combinedTables,
        tableView,
        allState.subscription.activities[drillHole.activity],
        isSample,
    );

    if (linkedToLithoTable) {
        const tableView = allState.subscription.tableViews[lithologyTable];

        if (lithologyIntervalTree?.size) {
            const {
                linkedRowReferences,
                maxRowSpanCount: maxSpan,
                temporaryRows,
            } = linkLithologyToRowReferences(
                items,
                lithologyIntervalTree,
                linkedToLithoTable,
                sampleTableId,
                state.skipAllTempLithoRows,
                {
                    collar: drillHole.id,
                    project: drillHole.project,
                    activity: drillHole.activity,
                    tableView: tableView.id,
                },
            );
            loadRowReferenceState.rowReferences = linkedRowReferences;
            maxRowSpanCount = maxSpan;
            lithologyTemporaryRows = temporaryRows;
        }

        modifyColumnsForLinkedToLithology(
            columnData,
            tableView.label,
            tableView.id,
            allState.subscription.activities[drillHole.activity],
            linkedToLithoTable,
            isSample,
            preferences?.showRowNumbers,
        );
    }
    if (!loadRowReferenceState.rowReferencesIntervalTree) {
        loadRowReferenceState.rowReferencesIntervalTree = new IntervalTree();
    }

    if (preferences?.showRowNumbers) {
        addRowNumberColumn(columnData);
    }
    // isSample, used in construct table row to perform validations accordingly
    const rows = constructTableRows(
        loadRowReferenceState.rowReferences,
        drillHole,
        columnData.columns,
        tableView,
        loadRowReferenceState.rowReferencesIntervalTree,
    )(combinedTables, allState.subscription.lists, samples, linkedToLithoTable, isXRF, isSample);

    return {
        ...state,
        ...columnData,
        tableView,
        rows: createDictionary(rows),
        ...loadRowReferenceState,
        maxRowSpanCount,
        lithologyTemporaryRows,
        rowReferencesLoadState: {
            status: LOAD_STATUS_COMPLETE,
            error: null,
        },
        sampleTableId,
    };
};

const loadRowReferencesFailureReducer = (state: DrillholeState, action: BaseAction) => {
    const { error } = action.payload;
    return {
        ...state,
        rowReferencesLoadState: failureReducer({ ...state.rowReferencesLoadState, error }),
    };
};

const loadSampleCompleteReducer = (state: DrillholeState, action: BaseAction) => {
    const { payload } = action;
    const sampleDict = Object.assign({}, ...payload.map((x: Sample) => ({ [x.id]: x })));

    return {
        ...state,
        samples: { ...state.samples, ...sampleDict },
    };
};

const reIndexCompleteReducer = (state: DrillholeState, action: BaseAction) => {
    const { response } = action.payload;
    const rowReferenceIndexes = response.RowReferenceIndexes;
    const rowReferences = { ...state.rowReferences };

    for (const k in rowReferenceIndexes) {
        if (k in rowReferences) {
            const rowReference = rowReferences[k]!;
            rowReference.index = rowReferenceIndexes[k];
        }
    }

    return {
        ...state,
        modifiedRowReferencesState: completeReducer({}),
        rowReferences,
    };
};

const modifiedRowReferencesPendingReducer = (state: DrillholeState, _action: BaseAction) => ({
    ...state,
    modifiedRowReferencesState: pendingReducer({}),
});

const exportCollarsPendingReducer = (state: DrillholeState, _action: BaseAction) => ({
    ...state,
    exportCollarsState: pendingReducer({}),
});
const exportCollarsCompleteReducer = (state: DrillholeState, _action: BaseAction) =>
    completeReducer({
        ...state,
        exportCollarsState: completeReducer({}),
    });

const exportCollarsFailureReducer = (state: DrillholeState, action: BaseAction) => {
    const { error } = action.payload;
    return {
        ...state,
        exportCollarsState: failureReducer({ error }),
    };
};

// =================================================================
const customExportPendingReducer = (state: DrillholeState, _action: BaseAction) => ({
    ...state,
    customExportState: pendingReducer({}),
});

const customExportCompleteReducer = (state: DrillholeState, _action: BaseAction) =>
    completeReducer({
        ...state,
        customExportState: completeReducer({}),
    });
const customExportFailureReducer = (state: DrillholeState, action: BaseAction) => {
    const { error } = action.payload;
    return {
        ...state,
        customExportState: failureReducer({ error }),
    };
};

const getExportTemplatesPendingReducer = (state: DrillholeState, _action: BaseAction) => ({
    ...state,
    exportTemplatesState: pendingReducer(state),
});

const getExportTemplatesCompleteReducer = (state: DrillholeState, action: BaseAction) => {
    const { payload } = action;
    return {
        ...state,
        exportTemplates: payload,
        exportTemplatesState: completeReducer({}),
    };
};
const getExportTemplatesFailureReducer = (state: DrillholeState, action: BaseAction) => {
    const { error } = action.payload;
    return {
        ...state,
        exportTemplatesState: failureReducer({ ...state.exportTemplatesState, error }),
    };
};

const getCustomExportsPendingReducer = (state: DrillholeState, _action: BaseAction) => ({
    ...state,
    exportTemplatesState: pendingReducer(state),
});

const getCustomExportsCompleteReducer = (state: DrillholeState, action: BaseAction) => {
    const { payload } = action;
    return {
        ...state,
        customExports: payload,
        exportTemplatesState: completeReducer({}),
    };
};
const getCustomExportsFailureReducer = (state: DrillholeState, action: BaseAction) => {
    const { error } = action.payload;
    return {
        ...state,
        exportTemplatesState: failureReducer({ ...state.exportTemplatesState, error }),
    };
};
// =================================================================

const clearExportCollarStateReducer = (state: DrillholeState, _action: BaseAction) =>
    completeReducer({
        ...state,
        exportCollarsState: INITIAL_EXPORT_COLLARS_STATE,
        customExportState: INITIAL_EXPORT_COLLARS_STATE,
    });

const saveRowReferencesCompleteReducer = (state: DrillholeState, action: BaseAction) => {
    let maxRowSpanCount = state.maxRowSpanCount ?? 1;
    let lithologyTemporaryRows: string[] = state.lithologyTemporaryRows;
    const rows = { ...state.rows };
    const rowReferences = { ...state.rowReferences };
    const { rowReferencesIntervalTree } = state;
    let rowReferencesListPointers = { ...state.rowReferencesListPointers };
    const {
        response,
        isInsert,
        allState,
        isSample,
        isSorted = false,
        isDeleted = false,
    } = action.payload;
    const {
        RowReference: rowRefs = [] as RowReference[],
        Collar: drillhole = {} as Drillhole,
        NextRows: nextRows = {} as { [id: string]: RowReference },
    } = response;

    const modifiedRows = combineNewRows(rowRefs, nextRows);
    const linkedToLithoTable = state.linkedToLithoTable;
    let rowReferenceObject: { [key: string]: RowReference } = {};
    let reconstructAllRows = false;
    let rowsObject = isInsert || isSorted ? {} : rows;
    const sortedRows: RowReference[] = [];
    let atLeastOneNewRow = false;
    for (const modifiedRow of modifiedRows) {
        const rowReferenceSample = state.samples[modifiedRow.sample ?? ''];
        const oldRowReferenceObject = rowReferences[modifiedRow.id];
        // Remove previous interval from interval tree
        removeIntervalIfPresent(rowReferencesIntervalTree, oldRowReferenceObject);
        const rowReference = oldRowReferenceObject
            ? { ...oldRowReferenceObject, ...modifiedRow }
            : { ...modifiedRow };

        attachTempResultData(oldRowReferenceObject, rowReference, [
            { table: SAMPLE_RESULTS, validData: isSample },
            {
                table: state.linkedToLithoTable,
                validData: !!state.linkedToLithoTable && !!state.lithologyIntervalTree?.size,
            },
        ]);

        // Update row references will be set to state later
        rowReferences[modifiedRow.id] = rowReference;

        if (isSorted) {
            reconstructAllRows = true;
            sortedRows.push({ ...rowReference, nextItem: null, prevItem: null });
            continue;
        }

        if (!oldRowReferenceObject) {
            reconstructAllRows = true;
            atLeastOneNewRow = true;
            rowReferenceObject = createRowReferencesObjectForNewRow(
                rowReferencesListPointers,
                rowReference,
                modifiedRow.index,
                !!state.lithologyIntervalTree?.size,
            );
        } else if (
            compareIntervalsWithoutLength(
                { ...oldRowReferenceObject.values },
                { ...rowReference.values },
            ) ||
            rowReferenceSample ||
            isDeleted
        ) {
            // Interval values changed or sample table.
            reconstructAllRows = true;
            if (atLeastOneNewRow) {
                // When we have a new row the rowReferenceObject has all the rows in correct order
                // with the new rows inserted in at correct place. This should be used instead of
                // rowReferences.
                rowReferenceObject[modifiedRow.id] = rowReference;
            } else {
                rowReferenceObject = rowReferences;
            }
        } else {
            // Change in non interval values
            rowReferenceObject[modifiedRow.id] = rowReference;
        }

        adjustListPointers(rowReference, rowReferencesListPointers);
    }

    if (isSorted) {
        const rowReferenceData = processLoadedRowReferences(state, sortedRows);
        rowReferenceObject = rowReferenceData.rowReferences;
        rowReferencesListPointers = rowReferenceData.rowReferencesListPointers;
    }

    if (reconstructAllRows && state.lithologyIntervalTree?.size) {
        const tempRowReferences: { [key: string]: RowReference } = {};
        rowsObject = {};

        let temp = rowReferencesListPointers.firstRowReference;

        while (temp) {
            if (linkedToLithoTable in temp.dataRecords) {
                delete temp.dataRecords[linkedToLithoTable];
            }

            tempRowReferences[temp.id] = temp;
            temp = temp.nextItem;
        }

        const {
            linkedRowReferences,
            maxRowSpanCount: maxSpan,
            temporaryRows,
        } = linkLithologyToRowReferences(
            Object.values(tempRowReferences),
            state.lithologyIntervalTree,
            linkedToLithoTable,
            state.sampleTableId,
            state.skipAllTempLithoRows,
            {
                collar: drillhole.id,
                project: drillhole.project,
                activity: drillhole.activity,
                tableView: state.tableView.id,
            },
        );
        rowReferenceObject = linkedRowReferences;
        maxRowSpanCount = maxSpan;
        lithologyTemporaryRows = temporaryRows;
    }
    // isSample, used in construct table row to perform validations accordingly
    const rowsToUpdate: any[] = constructTableRows(
        rowReferenceObject,
        response.Collar,
        state.columns,
        state.tableView,
        rowReferencesIntervalTree,
    )(
        { ...allState.subscription.tables, ...state.temporaryTables },
        allState.subscription.lists,
        state.samples,
        state.linkedToLithoTable,
        false,
        isSample,
    );

    for (const r of rowsToUpdate) {
        if (!reconstructAllRows) {
            r[OUT_OF_ORDER] = rows[r.id][OUT_OF_ORDER];
            r[ROW_NUMBER] = rows[r.id][ROW_NUMBER];
        }

        rowsObject[r.id] = r;
    }

    const rowReferencesCollection =
        isInsert || reconstructAllRows ? rowReferenceObject : rowReferences;

    const {
        items: { collars },
    } = state;
    const newItems = { ...collars };
    if (response.Collar) {
        newItems[response.Collar.id] = { ...newItems[response.Collar.id], ...drillhole };
    }

    return completeReducer({
        ...state,
        rows: rowsObject,
        rowReferences: rowReferencesCollection,
        rowReferencesListPointers,
        modifiedRowReferencesState: completeReducer({}),
        modifiedRows: {
            isSorted,
            reconstructAllRows,
            rows: modifiedRows,
            redrawRows: [],
            isSequenceChanged: isSorted || isInsert || isDeleted,
        },
        items: { ...state.items, collars: newItems },
        maxRowSpanCount,
        lithologyTemporaryRows,
    });
};

const modifiedRowReferencesFailureReducer = (state: DrillholeState, action: BaseAction) => {
    const { error } = action.payload;
    return { ...state, modifiedRowReferencesState: failureReducer({ error }) };
};

const deleteRowReferencesCompleteReducer = (state: DrillholeState, action: BaseAction) => {
    let maxRowSpanCount = state.maxRowSpanCount ?? 1;
    let lithologyTemporaryRows: string[] = state.lithologyTemporaryRows;
    const { response, allState, isSample } = action.payload;
    const rowReferencesListPointers = { ...state.rowReferencesListPointers };
    const { rowReferencesIntervalTree } = state;
    const { rowsDeleted, Collar: drillHole = {} as Drillhole } = response;
    const linkedToLithoTable = state.linkedToLithoTable;
    let rowReferences = { ...state.rowReferences };
    const modifiedRows: RowReference[] = [];

    for (const rowId of rowsDeleted) {
        const rowReference = rowReferences[rowId]!;
        removeIntervalIfPresent(rowReferencesIntervalTree, rowReference);
        modifiedRows.push(rowReference);

        // Update list pointers
        if (rowReference.prevItem) {
            rowReference.prevItem.nextItem = rowReference.nextItem;
        } else {
            rowReferencesListPointers.firstRowReference = rowReference.nextItem;
        }
        if (rowReference.nextItem) {
            rowReference.nextItem.prevItem = rowReference.prevItem;
        } else {
            rowReferencesListPointers.lastRowReference = rowReference.prevItem;
        }
        delete rowReferences[rowId];
    }

    if (state.lithologyIntervalTree?.size) {
        rowReferences = {};
        let temp = rowReferencesListPointers.firstRowReference;

        while (temp) {
            if (linkedToLithoTable in temp.dataRecords) {
                delete temp.dataRecords[linkedToLithoTable];
            }

            rowReferences[temp.id] = temp;
            temp = temp.nextItem;
        }

        const {
            linkedRowReferences,
            maxRowSpanCount: maxSpan,
            temporaryRows,
        } = linkLithologyToRowReferences(
            Object.values(rowReferences),
            state.lithologyIntervalTree,
            linkedToLithoTable,
            state.sampleTableId,
            state.skipAllTempLithoRows,
            {
                collar: drillHole.id,
                project: drillHole.project,
                activity: drillHole.activity,
                tableView: state.tableView.id,
            },
        );
        rowReferences = linkedRowReferences;
        maxRowSpanCount = maxSpan;
        lithologyTemporaryRows = temporaryRows;
    }

    // Reconstruct entire table rows
    // isSample, used in construct table row to perform validations accordingly
    const rows = constructTableRows(
        rowReferences,
        drillHole,
        state.columns,
        state.tableView,
        rowReferencesIntervalTree,
    )(
        { ...allState.subscription.tables, ...state.temporaryTables },
        allState.subscription.lists,
        state.samples,
        state.linkedToLithoTable,
        false,
        isSample,
    );

    const {
        items: { collars },
    } = state;
    const newItems = { ...collars };
    newItems[drillHole.id] = { ...newItems[drillHole.id], ...drillHole };
    return completeReducer({
        ...state,
        rows: createDictionary(rows),
        items: { ...state.items, collars: newItems },
        rowReferences,
        rowReferencesListPointers,
        modifiedRowReferencesState: completeReducer({}),
        modifiedRows: {
            reconstructAllRows: true,
            rows: modifiedRows,
            isSorted: false,
            redrawRows: [],
            isSequenceChanged: true,
        },
        maxRowSpanCount,
        lithologyTemporaryRows,
    });
};

const addFileGroupPendingReducer = (state: DrillholeState, _action: BaseAction) => ({
    ...state,
    fileGroup: pendingReducer(state.fileGroup),
});

const addFileGroupCompleteReducer = (state: DrillholeState, action: BaseAction) => {
    const fileGroup = action.payload;

    return {
        ...state,
        fileGroup: completeReducer({ ...state.fileGroup, fileGroup }),
    };
};

const addFileGroupFailureReducer = (state: DrillholeState, action: BaseAction) => {
    const { error } = action.payload;
    return { ...state, fileGroup: failureReducer({ ...state.fileGroup, error }) };
};

const deleteFilePendingReducer = (state: DrillholeState, _action: BaseAction) => ({
    ...state,
    deletedFile: pendingReducer(state.deletedFile),
});

const deleteFileCompleteReducer = (state: DrillholeState, action: BaseAction) => {
    const deletedFile = action.payload;
    return {
        ...state,
        deletedFile: completeReducer({ deletedFile }),
    };
};

const deleteFileFailureReducer = (state: DrillholeState, action: BaseAction) => {
    const { error } = action.payload;
    return { ...state, deletedFile: failureReducer({ ...state.deletedFile, error }) };
};

const downloadFilePendingReducer = (state: DrillholeState, _action: BaseAction) =>
    pendingReducer(state);

const downloadFileCompleteReducer = (state: DrillholeState, action: BaseAction) => {
    const { response: downloadedFile } = action.payload;
    return completeReducer({
        ...state,
        downloadedFile,
    });
};

const downloadFileFailureReducer = (state: DrillholeState, action: BaseAction) => {
    const { error } = action.payload;
    return failureReducer({ ...state, error });
};

const uploadFilePendingReducer = (state: DrillholeState, _action: BaseAction) =>
    pendingReducer(state);

const uploadFileCompleteReducer = (state: DrillholeState, action: BaseAction) => {
    const result = action.payload;

    const uploadedFiles = [...state.uploadedFiles];
    uploadedFiles.push(result);

    return completeReducer({
        ...state,
        uploadedFiles,
    });
};

const uploadFileFailureReducer = (state: DrillholeState, action: BaseAction) => {
    const { error } = action.payload;
    return failureReducer({ ...state, error });
};

const dumpTableDataCompleteReducer = (state: DrillholeState, action: BaseAction) => {
    const { url } = action.payload;
    return completeReducer({
        ...state,
        dumpTableData: completeReducer({ url }),
    });
};

// set dumpTableData to pending
const dumpTableDataPendingReducer = (state: DrillholeState, _action: BaseAction) =>
    pendingReducer({ ...state, dumpTableData: { ...state, status: 'pending' } });

const dumpTableDataFailureReducer = (state: DrillholeState, action: BaseAction) => {
    const { error } = action.payload;
    return { ...state, dumpTableData: failureReducer({ ...state.dumpTableData, error }) };
};

const getFilesForGroupPendingReducer = (state: DrillholeState, _action: BaseAction) => ({
    ...state,
    filesForGroup: pendingReducer(state.filesForGroup),
});

const getFilesForGroupCompleteReducer = (state: DrillholeState, action: BaseAction) => {
    const { response: result, fileGroupId } = action.payload;

    const { filesForGroup: filesForGroupOrig } = cloneDeep(state.filesForGroup);
    filesForGroupOrig[fileGroupId] = { ...result };
    const filesForGroupLengths = cloneDeep(state.filesForGroupLengths);
    filesForGroupLengths[fileGroupId] = Object.keys(filesForGroupOrig[fileGroupId]).length;
    const filesForGroup = { ...filesForGroupOrig };

    return {
        ...state,
        filesForGroup: completeReducer({ filesForGroup }),
        filesForGroupLengths,
    };
};

const getFilesForGroupFailureReducer = (state: DrillholeState, action: BaseAction) => {
    const { error } = action.payload;
    return { ...state, filesForGroup: failureReducer({ ...state.filesForGroup, error }) };
};

const addDrillHolePendingReducer = (state: DrillholeState, _action: BaseAction) => ({
    ...state,
    addDrillHoleState: pendingReducer({}),
});

const addDrillHoleCompleteReducer = (state: DrillholeState, action: BaseAction) => {
    const { Collar } = action.payload;
    const {
        items: { collars },
    } = state;
    const newItems = { ...collars };
    const current = { ...state.current };
    newItems[Collar.id] = Collar;
    current.id = Collar.id;
    return {
        ...state,
        items: { ...state.items, collars: newItems },
        current,
        addDrillHoleState: completeReducer({}),
    };
};

const addDrillHoleFailureReducer = (state: DrillholeState, action: BaseAction) => {
    const { error } = action.payload;
    return { ...state, addDrillHoleState: failureReducer({ error }) };
};

const cloneDrillHolePendingReducer = (state: DrillholeState, _action: BaseAction) => ({
    ...state,
    cloneDrillHoleState: pendingReducer({}),
});

const cloneDrillHoleCompleteReducer = (state: DrillholeState, action: BaseAction) => {
    const collar = action.payload;
    const {
        items: { collars },
    } = state;
    const newItems = { ...collars };
    const current = { ...state.current };
    newItems[collar.id] = collar;
    current.id = collar.id;

    return {
        ...state,
        items: { ...state.items, collars: newItems },
        current,
        cloneDrillHoleState: completeReducer({}),
    };
};

const cloneDrillHoleFailureReducer = (state: DrillholeState, action: BaseAction) => {
    const { error } = action.payload;
    return { ...state, cloneDrillHoleState: failureReducer({ error }) };
};

const clearCloneDrillHoleStateReducer = (state: DrillholeState, _action: BaseAction) =>
    completeReducer({
        ...state,
        cloneDrillHoleState: INITIAL_ADD_NEW_DRILL_HOLE_STATE,
    });

const clearRowReferenceResponseReducer = (state: DrillholeState, _action: BaseAction) => {
    const { rowReferencesIntervalTree } = state;
    rowReferencesIntervalTree?.clear();
    return {
        ...state,
        ...INTIAL_ROW_REFERENCE_STATE,
    };
};

const clearFilesStateCompleteReducer = (state: DrillholeState, _action: BaseAction) =>
    completeReducer({
        ...state,
        fileGroup: {
            ...INITIAL_FILE_GROUP,
            fileGroup: {} as DataFileGroupResponse | HeaderFileGroupResponse,
        },
        uploadedFiles: [],
        filesForGroup: {
            ...INITIAL_FILES_FOR_GROUP,
            filesForGroup: {} as { [id: string]: FilesForGroup },
        },
        deletedFile: { ...INITIAL_DELETED_FILE_STATE, deletedFile: {} as FileObject },
    });

const clearSaveStateReducer = (state: DrillholeState, _action: BaseAction) =>
    completeReducer({
        ...state,
        saveDrillHoleState: INITIAL_SAVE_STATE_STALE,
        modifiedRowReferencesState: INITIAL_SAVE_STATE,
    });

const clearDeleteDrillHoleStateReducer = (state: DrillholeState, _action: BaseAction) =>
    completeReducer({
        ...state,
        deleteDrillHoleState: INITIAL_DELETE_DRILL_HOLE_STATE,
    });

const clearAddDrillHoleStateReducer = (state: DrillholeState, _action: BaseAction) =>
    completeReducer({
        ...state,
        addDrillHoleState: INITIAL_ADD_NEW_DRILL_HOLE_STATE,
    });

const clearSaveRowReferenceStateReducer = (state: DrillholeState, _action: BaseAction) => ({
    ...state,
    modifiedRowReferencesState: INITIAL_SAVE_STATE,
});

const clearDownloadedFile = (state: DrillholeState, _action: BaseAction) =>
    completeReducer({
        ...state,
        downloadedFile: null,
    });

const clearDumpDataFile = (state: DrillholeState, _action: BaseAction) =>
    completeReducer({
        ...state,
        dumpTableData: completeReducer({ url: null }),
    });

const clearFilesForGroupLengthStateCompleteReducer = (state: DrillholeState, _action: BaseAction) =>
    completeReducer({
        ...state,
        filesForGroupLengths: {},
    });

const deleteDrillHolePendingReducer = (state: DrillholeState, _action: BaseAction) => ({
    ...state,
    deleteDrillHoleState: pendingReducer({}),
});
const deleteDrillHoleCompleteReducer = (state: DrillholeState, action: BaseAction) => {
    const drillHole = action.payload;
    const {
        items: { collars },
    } = state;
    const newItems = { ...collars };
    delete newItems[drillHole.id];

    const selectedDrillHoles = state.selectedDrillHoles.filter((d) => d.id !== drillHole.id);
    const unSelectedCollars = { ...state.unSelectedCollars };
    delete unSelectedCollars[drillHole.id];
    return completeReducer({
        ...state,
        items: { ...state.items, collars: newItems },
        selectedDrillHoles,
        unSelectedCollars,
        count: state.count - 1,
        deleteDrillHoleState: completeReducer({}),
    });
};

const deleteDrillHoleFailureReducer = (state: DrillholeState, action: BaseAction) => {
    const { error } = action.payload;
    return { ...state, deleteDrillHoleState: failureReducer({ error }) };
};

const loadLabCertificateInfoPendingReducer = (state: DrillholeState, _action: BaseAction) => ({
    ...state,
    labCertificateInfoState: pendingReducer({
        labCertInfo: state.labCertificateInfoState.labCertInfo,
    }),
});

const loadLabCertificateInfoCompleteReducer = (state: DrillholeState, action: BaseAction) => {
    const loadLabCertificateInfo: LabCertificateInfo[] = action.payload;
    const dict = Object.assign(
        { ...state.labCertificateInfoState.labCertInfo },
        ...loadLabCertificateInfo.map((x: LabCertificateInfo) => ({ [x.id]: x })),
    );

    return {
        ...state,
        labCertificateInfoState: completeReducer({ labCertInfo: dict }),
    };
};

const loadLabCertificateInfoFailureReducer = (state: DrillholeState, action: BaseAction) => {
    const { error } = action.payload;
    return {
        ...state,
        labCertificateInfoState: failureReducer({
            error,
            labCertInfo: state.labCertificateInfoState.labCertInfo,
        }),
    };
};

const loadEmptyFileGroupsPendingReducer = (state: DrillholeState, _action: BaseAction) =>
    pendingReducer({ ...state });

const loadEmptyFileGroupsCompleteReducer = (state: DrillholeState, action: BaseAction) => {
    const {
        payload: { groups, tableViewId },
    } = action;
    const emptyFiles = { ...state.emptyFileGroups };
    emptyFiles[tableViewId] = groups.map((x: any) => x.id);
    return completeReducer({
        ...state,
        emptyFileGroups: emptyFiles,
    });
};

const clearEmptyFileGroupsCompleteReducer = (state: DrillholeState, _action: BaseAction) =>
    completeReducer({
        ...state,
        emptyFileGroups: {},
    });

const loadEmptyFileGroupsFailureReducer = (state: DrillholeState, action: BaseAction) => {
    const { error } = action.payload;
    return failureReducer({ ...state, error });
};

const unassignUserFromDrillholePendingReducer = (state: DrillholeState, _action: BaseAction) => ({
    ...state,
    unassignUserState: pendingReducer(state.unassignUserState),
});

const unassignUserFromDrillholeCompleteReducer = (state: DrillholeState, _action: BaseAction) => ({
    ...state,
    unassignUserState: completeReducer(state.unassignUserState),
});

const unassignUserFromDrillholeFailureReducer = (state: DrillholeState, action: BaseAction) => {
    const { error } = action.payload;
    return { ...state, unassignUserState: failureReducer({ ...state.unassignUserState, error }) };
};

const clearUnassignUserFromDrillholeStateReducer = (
    state: DrillholeState,
    _action: BaseAction,
) => ({
    ...state,
    unassignUserState: INITIAL_UNASSIGN_USER_STATE,
});

const loadCoordinatesTableCompleteReducer = (state: DrillholeState, action: BaseAction) => {
    const { allState, stateToUpdate, buildColumns } = action.payload;
    const drillhole: Drillhole = action.payload.drillhole;
    const tableView: TableView =
        allState.subscription.tableViews[`${drillhole.header}-${COORDINATES_ID}`];
    let rowReferences: { [id: string]: RowReference } = {};

    Object.entries(drillhole.coordinatesTable.rows)
        .sort(([_keyA, RowA], [_keyB, RowB]) => RowA.rank - RowB.rank)
        .forEach(([key, row]) => {
            const rowReference: RowReference = convertRowToRowReference(
                key,
                row,
                drillhole,
                tableView,
            );
            rowReferences = { ...rowReferences, [key]: rowReference };
        });

    let columnData: any = {};
    const {
        items: { collars },
    } = state;
    const newItems = { ...collars };
    let stateUpdate = '';

    switch (stateToUpdate) {
        case LOAD_ROW_REFERENCES:
            stateUpdate = `rowReferencesLoadState`;
            break;
        case DELETE_ROW_REFERENCES:
        case SAVE_ROW_REFERENCES:
            stateUpdate = `modifiedRowReferencesState`;
            break;
        default:
            break;
    }

    if (buildColumns) {
        columnData = constructTableColumns(
            allState.subscription.tables,
            tableView,
            allState.subscription.activities[drillhole.activity],
            false,
        );
    } else {
        newItems[drillhole.id] = { ...newItems[drillhole.id], ...drillhole };
    }
    // This is coordinates table we pass table related flags used in row building false explicitly
    const rows = constructTableRows(
        rowReferences,
        drillhole,
        buildColumns ? columnData.columns : state.columns,
        tableView,
    )(allState.subscription.tables, allState.subscription.lists, {}, '', false, false);

    const rowDictionary = Object.assign({}, ...rows.map((x: any) => ({ [x.id]: x })));

    return completeReducer({
        ...state,
        rows: rowDictionary,
        rowReferences,
        ...(buildColumns ? columnData : { items: { ...state.items, collars: newItems } }),
        [stateUpdate]: completeReducer({}),
        tableView,
    });
};

const resetDrillholeStateReducer = (_state: DrillholeState, _action: BaseAction) => ({
    ...INITIAL_STATE,
});

const toggleSpanReducer = (state: DrillholeState, action: BaseAction) => {
    const rowReferences = { ...state.rowReferences };
    const rows = { ...state.rows };
    const temporaryRows = state.lithologyTemporaryRows;
    const table = state.linkedToLithoTable;
    const { toggleInfo, allState, isSample } = action.payload;
    const { id, value } = toggleInfo;
    const redrawRows: string[] = [];
    const items = Object.values(rowReferences);
    const currentDrillHole = { ...state.current };
    const {
        items: { collars: dholeItems },
    } = state;
    const drillHole = { ...dholeItems[items[0]?.collar ? items[0]?.collar : currentDrillHole.id] };

    const updateTempRows = (rowId: string) => {
        let currentRow = rowReferences[rowId];
        redrawRows.push(rowId);
        while (currentRow) {
            if (
                PARENT_SKIP_LITHOLOGY_ROW in rowReferences[currentRow.id].dataRecords[table].values
            ) {
                rowReferences[currentRow.id].dataRecords[table].values[PARENT_SKIP_LITHOLOGY_ROW] =
                    value;
            }

            if (SKIP_LITHOLOGY_ROW in rowReferences[currentRow.id].dataRecords[table].values) {
                rowReferences[currentRow.id].dataRecords[table].values[SKIP_LITHOLOGY_ROW] = value;
            }

            currentRow = currentRow.nextItem;
        }
        // isSample, used in construct table row to perform validations accordingly
        const rowsToUpdate: any = constructTableRows(
            rowReferences,
            drillHole,
            state.columns,
            state.tableView,
            state.rowReferencesIntervalTree,
        )(
            { ...allState.subscription.tables, ...state.temporaryTables },
            allState.subscription.lists,
            state.samples,
            state.linkedToLithoTable,
            false,
            isSample,
        );

        for (const r of rowsToUpdate) {
            rows[r.id] = r;
        }
    };

    if (id === 'ALL') {
        temporaryRows.forEach((x: string) => updateTempRows(x));
    } else {
        updateTempRows(id);
    }

    return {
        ...state,
        rowReferences,
        rows,
        modifiedRows: { reconstructAllRows: true, isSorted: false, rows: [], redrawRows },
        ...(id === 'ALL' && { skipAllTempLithoRows: value }),
    };
};

const applyExternalFilterReducer = (state: DrillholeState, action: BaseAction) => ({
    ...state,
    externalFilter: action.payload,
});

const filterAppliedReducer = (state: DrillholeState, action: BaseAction) => ({
    ...state,
    filterApplied: action.payload,
});

const setSearchTermReducer = (state: DrillholeState, action: BaseAction) => ({
    ...state,
    searchTerm: action.payload,
});

export const reducer = mappedReducer(INITIAL_STATE, {
    [typeComplete(LOAD_DRILLHOLES_WITH_COUNT)]: loadDrillholesWithCountCompleteReducer,
    [typePending(LOAD_DRILLHOLES_WITH_COUNT)]: loadDrillholeListPendingReducer,
    [typeFail(LOAD_DRILLHOLES_WITH_COUNT)]: loadDrillholeListFailureReducer,

    [typeComplete(LOAD_DRILLHOLES)]: loadDrillholeListCompleteReducer,
    [typePending(LOAD_DRILLHOLES)]: loadDrillholeListPendingReducer,
    [typeFail(LOAD_DRILLHOLES)]: loadDrillholeListFailureReducer,
    [typeComplete(SAVE_DRILLHOLE)]: saveDrillHoleCompleteReducer,
    [typePending(SAVE_DRILLHOLE)]: saveDrillHolePendingReducer,
    [typeFail(SAVE_DRILLHOLE)]: saveDrillHoleFailureReducer,

    [typeComplete(SELECT_ALL_COLLARS)]: selectAllCollarsCompleteReducer,
    [typeComplete(CLEAR_ALL_COLLARS)]: clearAllCollarsCompleteReducer,

    [SELECT_COLLARS]: selectCollarsCompleteReducer,
    [SELECT_COLLARS_BY_ID]: selectCollarsByIdCompleteReducer,
    [typeComplete(UNSELECT_COLLARS)]: unSelectCollarsCompleteReducer,
    [typeComplete(SET_CURRENT_DRILLHOLE)]: setCurrentDrillholeReducer,

    [typeComplete(LOAD_DRILLHOLE)]: loadDrillholeCompleteReducer,
    [typePending(LOAD_DRILLHOLE)]: loadDrillholePendingReducer,
    [typeFail(LOAD_DRILLHOLE)]: loadDrillholeFailureReducer,

    [typeComplete(LOAD_ROW_REFERENCES)]: loadRowReferencesCompleteReducer,
    [typePending(LOAD_ROW_REFERENCES)]: loadRowReferencesPendingReducer,
    [typeFail(LOAD_ROW_REFERENCES)]: loadRowReferencesFailureReducer,

    [typeComplete(SAVE_ROW_REFERENCES)]: saveRowReferencesCompleteReducer,
    [typePending(SAVE_ROW_REFERENCES)]: modifiedRowReferencesPendingReducer,
    [typeFail(SAVE_ROW_REFERENCES)]: modifiedRowReferencesFailureReducer,

    [typeComplete(COPY_CELLS)]: saveRowReferencesCompleteReducer,
    [typePending(COPY_CELLS)]: modifiedRowReferencesPendingReducer,
    [typeFail(COPY_CELLS)]: modifiedRowReferencesFailureReducer,

    [typePending(COPY_COORDINATE_CELLS)]: modifiedRowReferencesPendingReducer,
    [typeFail(COPY_COORDINATE_CELLS)]: modifiedRowReferencesFailureReducer,

    [typeComplete(DELETE_ROW_REFERENCES)]: deleteRowReferencesCompleteReducer,
    [typePending(DELETE_ROW_REFERENCES)]: modifiedRowReferencesPendingReducer,
    [typeFail(DELETE_ROW_REFERENCES)]: modifiedRowReferencesFailureReducer,

    [typeComplete(FILL_DOWN_SAVE_ROW_REFERENCES)]: saveRowReferencesCompleteReducer,
    [typePending(FILL_DOWN_SAVE_ROW_REFERENCES)]: modifiedRowReferencesPendingReducer,
    [typeFail(FILL_DOWN_SAVE_ROW_REFERENCES)]: modifiedRowReferencesFailureReducer,

    [typeComplete(COPY_INTERVALS)]: saveRowReferencesCompleteReducer,
    [typePending(COPY_INTERVALS)]: modifiedRowReferencesPendingReducer,
    [typeFail(COPY_INTERVALS)]: modifiedRowReferencesFailureReducer,

    [typeComplete(ADD_DATA_FILE_GROUP)]: addFileGroupCompleteReducer,
    [typePending(ADD_DATA_FILE_GROUP)]: addFileGroupPendingReducer,
    [typeFail(ADD_DATA_FILE_GROUP)]: addFileGroupFailureReducer,

    [typeComplete(UPLOAD_FILE)]: uploadFileCompleteReducer,
    [typePending(UPLOAD_FILE)]: uploadFilePendingReducer,
    [typeFail(UPLOAD_FILE)]: uploadFileFailureReducer,

    [typeComplete(DUMP_TABLE_DATA)]: dumpTableDataCompleteReducer,
    [typePending(DUMP_TABLE_DATA)]: dumpTableDataPendingReducer,
    [typeFail(DUMP_TABLE_DATA)]: dumpTableDataFailureReducer,
    [typeComplete(CLEAR_DUMP_TABLE_DATA)]: clearDumpDataFile,

    [typeComplete(GET_FILES_FOR_GROUP)]: getFilesForGroupCompleteReducer,
    [typePending(GET_FILES_FOR_GROUP)]: getFilesForGroupPendingReducer,
    [typeFail(GET_FILES_FOR_GROUP)]: getFilesForGroupFailureReducer,

    [typeComplete(DELETE_FILE)]: deleteFileCompleteReducer,
    [typePending(DELETE_FILE)]: deleteFilePendingReducer,
    [typeFail(DELETE_FILE)]: deleteFileFailureReducer,

    [typeComplete(DOWNLOAD_FILE)]: downloadFileCompleteReducer,
    [typePending(DOWNLOAD_FILE)]: downloadFilePendingReducer,
    [typeFail(DOWNLOAD_FILE)]: downloadFileFailureReducer,

    [typeComplete(CLEAR_FILES_STATE)]: clearFilesStateCompleteReducer,
    [typeComplete(CLEAR_FILES_FOR_GROUP_LENGTHS_STATE)]:
        clearFilesForGroupLengthStateCompleteReducer,
    [typeComplete(CLEAR_SAVE_STATE)]: clearSaveStateReducer,
    [typeComplete(CLEAR_DELETE_DRILL_HOLE_STATE)]: clearDeleteDrillHoleStateReducer,
    [typeComplete(CLEAR_DOWNLOADED_FILE)]: clearDownloadedFile,

    [typeComplete(CREATE_ROWS)]: saveRowReferencesCompleteReducer,
    [typePending(CREATE_ROWS)]: modifiedRowReferencesPendingReducer,
    [typeFail(CREATE_ROWS)]: modifiedRowReferencesFailureReducer,

    [typeComplete(EXPORT_COLLAR)]: exportCollarsCompleteReducer,
    [typePending(EXPORT_COLLAR)]: exportCollarsPendingReducer,
    [typeFail(EXPORT_COLLAR)]: exportCollarsFailureReducer,

    [typeComplete(CUSTOM_EXPORT)]: customExportCompleteReducer,
    [typePending(CUSTOM_EXPORT)]: customExportPendingReducer,
    [typeFail(CUSTOM_EXPORT)]: customExportFailureReducer,

    [typeComplete(GET_EXPORT_TEMPLATES)]: getExportTemplatesCompleteReducer,
    [typePending(GET_EXPORT_TEMPLATES)]: getExportTemplatesPendingReducer,
    [typeFail(GET_EXPORT_TEMPLATES)]: getExportTemplatesFailureReducer,

    [typeComplete(GET_CUSTOM_EXPORTS)]: getCustomExportsCompleteReducer,
    [typePending(GET_CUSTOM_EXPORTS)]: getCustomExportsPendingReducer,
    [typeFail(GET_CUSTOM_EXPORTS)]: getCustomExportsFailureReducer,

    [typeComplete(CLEAR_EXPORT_COLLAR_STATE)]: clearExportCollarStateReducer,

    [typeComplete(ADD_DRILLHOLE)]: addDrillHoleCompleteReducer,
    [typePending(ADD_DRILLHOLE)]: addDrillHolePendingReducer,
    [typeFail(ADD_DRILLHOLE)]: addDrillHoleFailureReducer,
    [typeComplete(CLEAR_ADD_DRILL_HOLE_STATE)]: clearAddDrillHoleStateReducer,

    [typeComplete(CLONE_DRILHOLE)]: cloneDrillHoleCompleteReducer,
    [typePending(CLONE_DRILHOLE)]: cloneDrillHolePendingReducer,
    [typeFail(CLONE_DRILHOLE)]: cloneDrillHoleFailureReducer,
    [typeComplete(CLEAR_CLONE_DRILL_HOLE_STATE)]: clearCloneDrillHoleStateReducer,

    [typeComplete(RE_INDEX)]: reIndexCompleteReducer,
    [typePending(RE_INDEX)]: modifiedRowReferencesPendingReducer,
    [typeFail(RE_INDEX)]: modifiedRowReferencesFailureReducer,

    [typeComplete(DELETE_DRILLHOLE)]: deleteDrillHoleCompleteReducer,
    [typePending(DELETE_DRILLHOLE)]: deleteDrillHolePendingReducer,
    [typeFail(DELETE_DRILLHOLE)]: deleteDrillHoleFailureReducer,

    [CLEAR_ROW_REFERENCE_RESPONSE]: clearRowReferenceResponseReducer,
    [LOAD_SAMPLE]: loadSampleCompleteReducer,

    [typeComplete(LOAD_EMPTY_FILE_GROUPS)]: loadEmptyFileGroupsCompleteReducer,
    [typePending(LOAD_EMPTY_FILE_GROUPS)]: loadEmptyFileGroupsPendingReducer,
    [typeFail(LOAD_EMPTY_FILE_GROUPS)]: loadEmptyFileGroupsFailureReducer,

    [typeComplete(CLEAR_EMPTY_FILE_GROUPS)]: clearEmptyFileGroupsCompleteReducer,

    [typeComplete(SAVE_SPLIT_ROW_ROW_REFERENCES)]: saveRowReferencesCompleteReducer,
    [typePending(SAVE_SPLIT_ROW_ROW_REFERENCES)]: modifiedRowReferencesPendingReducer,
    [typeFail(SAVE_SPLIT_ROW_ROW_REFERENCES)]: modifiedRowReferencesFailureReducer,

    [typePending(MERGE_ROW_REFERENCES)]: modifiedRowReferencesPendingReducer,
    [typeFail(MERGE_ROW_REFERENCES)]: modifiedRowReferencesFailureReducer,

    [typeComplete(LOAD_LAB_CERTIFICATE_INFO)]: loadLabCertificateInfoCompleteReducer,
    [typePending(LOAD_LAB_CERTIFICATE_INFO)]: loadLabCertificateInfoPendingReducer,
    [typeFail(LOAD_LAB_CERTIFICATE_INFO)]: loadLabCertificateInfoFailureReducer,

    [typeComplete(LOAD_COORDINATES_TABLE)]: loadCoordinatesTableCompleteReducer,

    [CLEAR_SAVE_ROW_REFERENCE_STATE]: clearSaveRowReferenceStateReducer,

    [typeComplete(UNASSIGN_USER_FROM_DRILLHOLE)]: unassignUserFromDrillholeCompleteReducer,
    [typePending(UNASSIGN_USER_FROM_DRILLHOLE)]: unassignUserFromDrillholePendingReducer,
    [typeFail(UNASSIGN_USER_FROM_DRILLHOLE)]: unassignUserFromDrillholeFailureReducer,

    [CLEAR_UNASSIGN_USER_FROM_DHOLE_STATE]: clearUnassignUserFromDrillholeStateReducer,

    [TOGGLE_SPAN]: toggleSpanReducer,
    [APPLY_EXTERNAL_FILTER]: applyExternalFilterReducer,
    [FILTER_APPLIED]: filterAppliedReducer,

    [REFRESH_STATE]: resetDrillholeStateReducer,
    [SET_COLLAR_SEARCH_TERM]: setSearchTermReducer,
});
