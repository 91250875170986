import { createSelector } from 'reselect';

import { Activities, Activity, ActivityUsers, ProjectUsers } from 'state-domains/domain/project';

import { MapLayer } from 'src/components/Drillhole/DrillholeMap/DrillholeMap.types';

import { AsyncState, ShimState } from '../../../types';
import {
    isCompleteSelector,
    isFailedSelector,
    isPendingSelector,
    isStaleSelector,
} from '../../../utils';
import {
    OverviewCollar,
    OverviewMapCollarState,
    Project,
    ProjectBoundary,
    ProjectFileGroup,
    ProjectState,
} from '../types';
import { INITIAL_STATE } from './reducer';

const projectState = (state: Partial<ShimState>): ProjectState => {
    const { project = { ...INITIAL_STATE } } = state || {};
    return project;
};

const projectLoadState = createSelector(
    projectState,
    ({ projectLoadState: psProjectLoadState }: ProjectState): AsyncState => psProjectLoadState,
);

const pinProjectState = createSelector(
    projectState,
    ({ pinProjectState: psPinProjectState }: ProjectState): AsyncState => psPinProjectState,
);

const singleProjectLoadState = createSelector(
    projectState,
    ({ singleProjectLoadState: psSingleProjectLoadState }: ProjectState): AsyncState =>
        psSingleProjectLoadState,
);

const selectedProjectPermissionsAndTablesInActivitiesState = createSelector(
    projectState,
    ({
        selectedProjectPermissionsState: psSelectedProjectPermissionsState,
    }: ProjectState): AsyncState => psSelectedProjectPermissionsState,
);

const projects = createSelector(
    projectState,
    ({ projects = {} }: ProjectState): { [key: string]: Project } => projects,
);

const allProjects = createSelector(
    projectState,
    ({ allProjects = {} }: ProjectState): { [key: string]: Project } => allProjects,
);

const projectFileGroups = createSelector(
    projectState,
    ({ projectFileGroups = {} }: ProjectState): { [key: string]: ProjectFileGroup } =>
        projectFileGroups,
);

const projectFileGroup = (groupId: string) =>
    createSelector(projectFileGroups, (fileGroups) => fileGroups[groupId]);

const getProjectByUidFromAll = (uid: string) =>
    createSelector(allProjects, (allProjects): Project | undefined => allProjects[uid]);

const projectsList = createSelector(projectState, ({ projects = {} }: ProjectState): Project[] =>
    Object.values(projects),
);

const selectedProject = createSelector(
    projectState,
    ({ selected, projects }: ProjectState): Project | undefined =>
        (selected && projects[selected]) || undefined,
);

const selectedProjectPermissions = createSelector(
    projectState,
    ({ selectedProjectPermissions: psSelectedProjectPermissions }: ProjectState) =>
        psSelectedProjectPermissions,
);

const selectedProjectTablesInActivities = createSelector(
    projectState,
    ({ selectedProjectTablesInActivities: psSelectedProjectTablesInActivities }: ProjectState) =>
        psSelectedProjectTablesInActivities,
);

const getSearchTerm = createSelector(
    projectState,
    ({ searchTerm = '' }: ProjectState): string => searchTerm,
);

const getProjectByUid = (uid: string) =>
    createSelector(projectsList, (projectList): Project | undefined =>
        projectList.find((x: Project) => x.id === uid),
    );

const getProjectActivities = (uid: string) =>
    createSelector(getProjectByUid(uid), (project): Activities | undefined => project?.activities);

const getProjectActivity = (uid: string, activity: string) =>
    createSelector(
        getProjectActivities(uid),
        (activities = {}): Activity | undefined => activities[activity],
    );

const getProjectActivityUsers = (uid: string, activity: string) =>
    createSelector(
        getProjectActivity(uid, activity),
        (activity): ActivityUsers | undefined => activity?.users,
    );

const getProjectBoundaries = (uid: string, isFlat?: boolean) =>
    createSelector(getProjectByUid(uid), (project): ProjectBoundary[] => {
        if (!project?.boundary) {
            return [];
        }
        return project.boundary.features.map((feat) => ({
            coordinates: isFlat ? feat.geometry.coordinates.flat() : feat.geometry.coordinates,
            name: feat.properties.name,
        }));
    });

const getProjectUsers = (uid: string) =>
    createSelector(getProjectByUid(uid), (project): ProjectUsers | undefined => project?.users);

const isUserInProject = (userId: string, projectId: string) =>
    createSelector(getProjectUsers(projectId), (users): boolean => !!users && !!users[userId]);

const mapLayer = createSelector(projectState, ({ mapType: type }: ProjectState): MapLayer => type);

const overviewMapCollars = createSelector(
    projectState,
    ({ overviewMapCollars: overviewMapCollarsState }: ProjectState): AsyncState =>
        overviewMapCollarsState,
);
const overviewMapCollarItems = createSelector(
    overviewMapCollars,
    ({ items = {} }: OverviewMapCollarState): OverviewCollar[] => Object.values(items),
);

const overviewMapCollarCount = createSelector(
    overviewMapCollars,
    ({ count = 0 }: OverviewMapCollarState): number => count,
);

export const selectors = {
    projectState,
    pinProjectState,
    selectedProject,
    selectedProjectPermissions,
    selectedProjectTablesInActivities,
    projects,
    allProjects,
    projectFileGroups,
    projectFileGroup,
    projectsList,
    isPinProjectPending: isPendingSelector(pinProjectState),
    isPinProjectComplete: isCompleteSelector(pinProjectState),
    isPinProjectFailed: isFailedSelector(pinProjectState),
    isLoadProjectPending: isPendingSelector(singleProjectLoadState),
    isLoadProjectComplete: isCompleteSelector(singleProjectLoadState),
    isLoadProjectFailed: isFailedSelector(singleProjectLoadState),
    isProjectListPending: isPendingSelector(projectLoadState),
    isProjectListComplete: isCompleteSelector(projectLoadState),
    isProjectListFailed: isFailedSelector(projectLoadState),
    isSelectedProjectPermissionsStatePending: isPendingSelector(
        selectedProjectPermissionsAndTablesInActivitiesState,
    ),
    isSelectedProjectPermissionsStateComplete: isCompleteSelector(
        selectedProjectPermissionsAndTablesInActivitiesState,
    ),
    getProjectByUid,
    getProjectActivities,
    getProjectActivity,
    getProjectActivityUsers,
    getProjectBoundaries,
    getProjectUsers,
    isUserInProject,
    mapLayer,
    isLoadOverviewCollarsStale: isStaleSelector(overviewMapCollars),
    overviewMapCollarItems,
    isOverviewMapCollarItemsPending: isPendingSelector(overviewMapCollars),
    overviewMapCollarCount,
    getSearchTerm,
    getProjectByUidFromAll,
};
