import * as React from 'react';
import { Location, Navigate, Params, useLocation } from 'react-router-dom';

import { ACTIVITY_TYPES, CONFIGURATION_TYPES } from 'state-domains/constants';
import { CurrentSubscription, Subscription } from 'state-domains/domain';
import { Uid } from 'state-domains/types';

import { Nullable } from 'src/types';
import { truncateInputString } from 'src/utilities/formatters';

import { PathConstants, RouteParameters } from './pathConstants';
import { WithRouterComponentProps } from './withRouter';

export const RemoveTrailingSlash = ({ ...rest }) => {
    const location = useLocation();

    // If the last character of the url is '/'
    if (/\/.*\/$/.exec(location.pathname)) {
        return (
            <Navigate
                replace
                {...rest}
                to={{
                    pathname: location.pathname.replace(/\/+$/, ''),
                    search: location.search,
                }}
            />
        );
    }
    return null;
};

export interface NavCurrentRouteInfo {
    route: string;
    pathname: string;
}
export const setDocumentTitle = (segments: string[]) => {
    document.title = segments.map((segment) => truncateInputString(segment)).join(' | ');
};
export function getCurrentPathName(props: Partial<WithRouterComponentProps>): string {
    const { location } = props;
    const { pathname = '' } = location || {};
    return pathname;
}
export function getNavPathForRoute(props: WithRouterComponentProps): NavCurrentRouteInfo {
    const { location } = props;
    const { pathname = '' } = location || {};
    const splitPath = pathname.split('/');
    const indexToParse = 1;

    const domainPath = splitPath.length >= indexToParse ? splitPath[indexToParse] : '';

    return {
        route: `/${domainPath}`,
        pathname,
    };
}
export const BASE_PROJECT_PATH = `${PathConstants.PROJECTS.ROOT}`;
export const SELECTED_PROJECT_PATH = `${BASE_PROJECT_PATH}/${RouteParameters.projectId}`;
export const SELECTED_DRILLHOLE_PATH = `${BASE_PROJECT_PATH}/${RouteParameters.projectId}/${PathConstants.PROJECTS.DRILLHOLE.ROOT}/${RouteParameters.drillholeId}`;
export const SELECTED_POINTS_PATH = `${BASE_PROJECT_PATH}/${RouteParameters.projectId}/${PathConstants.PROJECTS.POINTS.ROOT}/${RouteParameters.pointId}`;
export const SELECTED_DRILLHOLE_TABLEVIEW_PATH = `${BASE_PROJECT_PATH}/${RouteParameters.projectId}/${PathConstants.PROJECTS.DRILLHOLE.ROOT}/${RouteParameters.drillholeId}/${PathConstants.PROJECTS.DRILLHOLE.TABLE_VIEW.ROOT}/${RouteParameters.tableViewId}`;
export const SELECTED_POINTS_TABLEVIEW_PATH = `${BASE_PROJECT_PATH}/${RouteParameters.projectId}/${PathConstants.PROJECTS.POINTS.ROOT}/${RouteParameters.pointId}/${PathConstants.PROJECTS.POINTS.TABLE_VIEW.ROOT}/${RouteParameters.tableViewId}`;
export const getSelectedProjectUrl = (projectId: Uid): string =>
    `${BASE_PROJECT_PATH}/${projectId}`;
export const getSelectedProjectDrillholesUrl = (projectId: Uid) =>
    `${getSelectedProjectUrl(projectId)}/${PathConstants.PROJECTS.DRILLHOLE.ROOT}`;
export function getProjectIdFromParams(params: Params): string {
    const { projectId = '' } = params;
    return projectId;
}
export function getActivityTypeFromParams(params: Params): ACTIVITY_TYPES {
    return params.drillholeId ? ACTIVITY_TYPES.DRILLING : ACTIVITY_TYPES.POINT;
}
export function getActivityTypeFromLocation(location: Location): ACTIVITY_TYPES {
    return location.pathname.includes(PathConstants.PROJECTS.DRILLHOLE.ROOT)
        ? ACTIVITY_TYPES.DRILLING
        : ACTIVITY_TYPES.POINT;
}
export const getSelectedProjectPointDataUrl = (projectId: Uid) =>
    `${getSelectedProjectUrl(projectId)}/${PathConstants.PROJECTS.POINTS.ROOT}`;
export const getSelectedPointUrl = (projectId: Uid, pointId: Uid) =>
    `${getSelectedProjectPointDataUrl(projectId)}/${pointId}`;

export const getSelectedDrillHoleUrl = (projectId: Uid, drillholeId: Uid) =>
    `${getSelectedProjectDrillholesUrl(projectId)}/${drillholeId}`;

export function getCollarIdFromParams(params: Params): string {
    const { drillholeId = '', pointId = '' } = params;
    return drillholeId || pointId;
}
export const getSelectedTableViewUrl = (
    projectId: Uid,
    drillholeId: Uid,
    tableViewId: Uid,
    type: ACTIVITY_TYPES,
    rowReference = '',
) => {
    const rowReferenceUrl = rowReference ? `?rowReference=${rowReference}` : '';

    return `${
        type === ACTIVITY_TYPES.DRILLING
            ? getSelectedDrillHoleUrl(projectId, drillholeId)
            : getSelectedPointUrl(projectId, drillholeId)
    }/${PathConstants.PROJECTS.DRILLHOLE.TABLE_VIEW.ROOT}/${tableViewId}${rowReferenceUrl}`;
};
export function getTableViewIdFromParams(params: Params): string {
    const { tableViewId = '' } = params;
    return tableViewId;
}
export const JOBS_PATH = `${PathConstants.EVENTS.ROOT}/${PathConstants.EVENTS.JOBS.ROOT}`;
export const CONFIGURATION_SITE_SETTINGS_PATH = PathConstants.CONFIGURATION.ROOT;
export const CONFIGURATION_LOGGING_PATH = `${PathConstants.CONFIGURATION.ROOT}/${PathConstants.CONFIGURATION.LOGGING.ROOT}`;
export const CONFIGURATION_ACTIVITIES_PROJECTS_PATH = `${PathConstants.CONFIGURATION.ROOT}/${PathConstants.CONFIGURATION.ACTIVITIES_PROJECTS.ROOT}`;
export const CONFIGURATION_SAMPLING_PATH = `${PathConstants.CONFIGURATION.ROOT}/${PathConstants.CONFIGURATION.SAMPLING.ROOT}`;
export const CONFIGURATION_ACCOUNT_PATH = `${PathConstants.CONFIGURATION.ROOT}/${PathConstants.CONFIGURATION.ACCOUNT.ROOT}`;

export const CONFIGURATION_LISTS_PATH = `${PathConstants.CONFIGURATION.ROOT}/${PathConstants.CONFIGURATION.LOGGING.ROOT}/${PathConstants.CONFIGURATION.LOGGING.LISTS.ROOT}`;
export const CONFIGURATION_LISTS_ITEM_PATH = `${CONFIGURATION_LISTS_PATH}/${RouteParameters.listId}`;
export const CONFIGURATION_LISTS_IMPORT_PATH = `${CONFIGURATION_LISTS_PATH}/bulk-list`;
export const CONFIGURATION_TABLES_PATH = `${PathConstants.CONFIGURATION.ROOT}/${PathConstants.CONFIGURATION.LOGGING.ROOT}/${PathConstants.CONFIGURATION.LOGGING.TABLES.ROOT}`;
export const CONFIGURATION_TABLES_ITEM_PATH = `${CONFIGURATION_TABLES_PATH}/${RouteParameters.tableId}`;
export const CONFIGURATION_FIELDS_PATH = `${PathConstants.CONFIGURATION.ROOT}/${PathConstants.CONFIGURATION.LOGGING.ROOT}/${PathConstants.CONFIGURATION.LOGGING.FIELDS.ROOT}`;
export const CONFIGURATION_FIELDS_ITEM_PATH = `${CONFIGURATION_FIELDS_PATH}/${RouteParameters.fieldId}`;
export const CONFIGURATION_HEADERS_PATH = `${PathConstants.CONFIGURATION.ROOT}/${PathConstants.CONFIGURATION.LOGGING.ROOT}/${PathConstants.CONFIGURATION.LOGGING.HEADERS.ROOT}`;
export const CONFIGURATION_HEADERS_ITEM_PATH = `${CONFIGURATION_HEADERS_PATH}/${RouteParameters.headerId}`;

export const CONFIGURATION_ACTIVITIES_PATH = `${PathConstants.CONFIGURATION.ROOT}/${PathConstants.CONFIGURATION.ACTIVITIES_PROJECTS.ROOT}/${PathConstants.CONFIGURATION.ACTIVITIES_PROJECTS.ACTIVITIES.ROOT}`;
export const CONFIGURATION_ACTIVITIES_ITEM_PATH = `${CONFIGURATION_ACTIVITIES_PATH}/${RouteParameters.activityId}`;
export const CONFIGURATION_ACTIVITIES_ITEM_CONFIGURE_LISTS_PATH = `${CONFIGURATION_ACTIVITIES_ITEM_PATH}/configure-lists/${RouteParameters.activityTableId}`;
export const CONFIGURATION_ACTIVITIES_ITEM_ASSOCIATE_COLUMNS_PATH = `${CONFIGURATION_ACTIVITIES_ITEM_PATH}/associate-columns/${RouteParameters.activityTableId}`;
export const CONFIGURATION_PROJECTS_PATH = `${PathConstants.CONFIGURATION.ROOT}/${PathConstants.CONFIGURATION.ACTIVITIES_PROJECTS.ROOT}/${PathConstants.CONFIGURATION.ACTIVITIES_PROJECTS.PROJECTS.ROOT}`;
export const CONFIGURATION_GRIDS_PATH = `${PathConstants.CONFIGURATION.ROOT}/${PathConstants.CONFIGURATION.ACTIVITIES_PROJECTS.ROOT}/${PathConstants.CONFIGURATION.ACTIVITIES_PROJECTS.GRIDS.ROOT}`;
export const CONFIGURATION_PROJECTS_ITEM_PATH = `${CONFIGURATION_PROJECTS_PATH}/${RouteParameters.projectId}`;
export const CONFIGURATION_EDIT_PROJECT_BOUNDARIES_PATH = `${CONFIGURATION_PROJECTS_ITEM_PATH}/edit-boundaries/`;
export const CONFIGURATION_EDIT_PROJECT_PERMISSIONS_PATH = `${CONFIGURATION_PROJECTS_ITEM_PATH}/activity/${RouteParameters.activityId}/user-permissions`;
export const CONFIGURATION_ACTIVITY_GROUPS_PATH = `${PathConstants.CONFIGURATION.ROOT}/${PathConstants.CONFIGURATION.ACTIVITIES_PROJECTS.ROOT}/${PathConstants.CONFIGURATION.ACTIVITIES_PROJECTS.ACTIVITY_GROUPS.ROOT}`;

export const CONFIGURATION_SAMPLING_LISTS_PATH = `${CONFIGURATION_SAMPLING_PATH}/${PathConstants.CONFIGURATION.SAMPLING.LISTS.ROOT}`;
export const CONFIGURATION_SAMPLING_LISTS_ITEM_PATH = `${CONFIGURATION_SAMPLING_LISTS_PATH}/${RouteParameters.listId}`;
export const CONFIGURATION_SAMPLING_LISTS_IMPORT_FROM_LIBRARY_PATH = `${CONFIGURATION_SAMPLING_LISTS_ITEM_PATH}/sample-codes-import`;
export const CONFIGURATION_SAMPLING_DISPATCH_FIELDS_PATH = `${CONFIGURATION_SAMPLING_PATH}/${PathConstants.CONFIGURATION.SAMPLING.DISPATCH_FIELDS.ROOT}`;
export const CONFIGURATION_SAMPLING_DISPATCH_HEADERS_PATH = `${CONFIGURATION_SAMPLING_PATH}/${PathConstants.CONFIGURATION.SAMPLING.DISPATCH_HEADERS.ROOT}`;
export const CONFIGURATION_SAMPLING_DISPATCH_HEADERS_ITEM_PATH = `${CONFIGURATION_SAMPLING_PATH}/${PathConstants.CONFIGURATION.SAMPLING.DISPATCH_HEADERS.ROOT}/${RouteParameters.headerId}`;
export const CONFIGURATION_SAMPLING_ANALYSIS_WORKFLOWS_PATH = `${CONFIGURATION_SAMPLING_PATH}/${PathConstants.CONFIGURATION.SAMPLING.ANALYSIS_WORKFLOWS.ROOT}`;
export const CONFIGURATION_SAMPLING_ANALYSIS_WORKFLOWS_ITEM_PATH = `${CONFIGURATION_SAMPLING_ANALYSIS_WORKFLOWS_PATH}/${RouteParameters.sampleWorkflowId}`;
export const CONFIGURATION_SAMPLING_ANALYSIS_WORKFLOWS_ITEM_PREP_PACKAGES = `${CONFIGURATION_SAMPLING_ANALYSIS_WORKFLOWS_ITEM_PATH}/sample-prep`;
export const CONFIGURATION_SAMPLING_ANALYSIS_WORKFLOWS_ITEM_ANALYSIS_PACKAGES = `${CONFIGURATION_SAMPLING_ANALYSIS_WORKFLOWS_ITEM_PATH}/sample-analysis`;

export const CONFIGURATION_GRIDS_ITEM_PATH = `${CONFIGURATION_GRIDS_PATH}/${RouteParameters.gridId}`;
export const CONFIGURATION_GRIDS_PREVIEW_PATH = `${CONFIGURATION_GRIDS_ITEM_PATH}/preview/`;
export const CONFIGURATION_GRIDS_SELECT_FROM_LIST = `${CONFIGURATION_GRIDS_PATH}/select-from-list`;

export const CONFIGURATION_USERS_PATH = `${PathConstants.CONFIGURATION.ROOT}/${PathConstants.CONFIGURATION.ACCOUNT.ROOT}/${PathConstants.CONFIGURATION.ACCOUNT.USERS.ROOT}`;
export const CONFIGURATION_CATEGORIES_PATH = `${PathConstants.CONFIGURATION.ROOT}/${PathConstants.CONFIGURATION.ACCOUNT.ROOT}/${PathConstants.CONFIGURATION.ACCOUNT.CATEGORIES.ROOT}`;

export const CONFIGURATION_EVO_PATH = `${PathConstants.CONFIGURATION.ROOT}/${PathConstants.CONFIGURATION.INTEGRATIONS.ROOT}/${PathConstants.CONFIGURATION.INTEGRATIONS.EVO.ROOT}`;

export const getSelectedActivityUrl = (activityId: string) =>
    `${CONFIGURATION_ACTIVITIES_PATH}/${activityId}`;
export const getActivityConfigureListsUrl = (activityId: string, tableId: string) =>
    `${CONFIGURATION_ACTIVITIES_PATH}/${activityId}/configure-lists/${tableId}`;
export const getActivityAssociateColumnsUrl = (activityId: string, tableId: string) =>
    `${CONFIGURATION_ACTIVITIES_PATH}/${activityId}/associate-columns/${tableId}`;
export const getConfigurationSelectedProjectUrl = (projectId: string) =>
    `${CONFIGURATION_PROJECTS_PATH}/${projectId}`;
export const getEditProjectBoundariesUrl = (projectId: string) =>
    `${CONFIGURATION_PROJECTS_PATH}/${projectId}/edit-boundaries`;
export const getSelectedGridUrl = (gridId: string) => `${CONFIGURATION_GRIDS_PATH}/${gridId}`;
export const getPreviewGridUrl = (gridId: string) =>
    `${CONFIGURATION_GRIDS_PATH}/${gridId}/preview`;
export const getEditUserPermissionsUrl = (
    projectId: string,
    activityId: string,
    userId?: string,
) => {
    const base = `${getConfigurationSelectedProjectUrl(projectId)}/activity/${activityId}/user-permissions`;
    return userId ? `${base}?userId=${userId}` : base;
};
export const getGridPreviewUrl = (gridId: string) =>
    `${CONFIGURATION_GRIDS_PATH}/${gridId}/preview`;

export const getSelectedWorkflowUrl = (workflowId: string) =>
    `${CONFIGURATION_SAMPLING_ANALYSIS_WORKFLOWS_PATH}/${workflowId}`;
export const getSamplePrepPackageUrl = (workflowId: string) =>
    `${getSelectedWorkflowUrl(workflowId)}/sample-prep`;
export const getSampleAnalysisPackageUrl = (workflowId: string) =>
    `${getSelectedWorkflowUrl(workflowId)}/sample-analysis`;

export const getSelectedConfigurationItemUrl = (type: string, id?: string) => {
    const urlMap: { [id: string]: string } = {
        [CONFIGURATION_TYPES.LISTS]: CONFIGURATION_LISTS_PATH,
        [CONFIGURATION_TYPES.TABLES]: CONFIGURATION_TABLES_PATH,
        [CONFIGURATION_TYPES.FIELDS]: CONFIGURATION_FIELDS_PATH,
        [CONFIGURATION_TYPES.HEADERS]: CONFIGURATION_HEADERS_PATH,
        [CONFIGURATION_TYPES.ACTIVITIES]: CONFIGURATION_ACTIVITIES_PATH,
        [CONFIGURATION_TYPES.PROJECTS]: CONFIGURATION_PROJECTS_PATH,
        [CONFIGURATION_TYPES.SAMPLE_LISTS]: CONFIGURATION_SAMPLING_LISTS_PATH,
        [CONFIGURATION_TYPES.SAMPLE_HEADERS]: CONFIGURATION_SAMPLING_DISPATCH_HEADERS_PATH,
        [CONFIGURATION_TYPES.SAMPLE_ANALYSIS_WORKFLOWS]:
            CONFIGURATION_SAMPLING_ANALYSIS_WORKFLOWS_PATH,
        [CONFIGURATION_TYPES.GRIDS]: CONFIGURATION_GRIDS_PATH,
        [CONFIGURATION_TYPES.EVO]: CONFIGURATION_EVO_PATH,
    };

    const idPart = id ? `/${id}` : '';

    return `${urlMap[type]}${idPart}`;
};
export const PREFERENCES_PATH = `${PathConstants.HOME.ROOT}/${PathConstants.HOME.PREFERENCES.ROOT}`;
export const PREFERENCES_DATA_ENTRY_PATH = `${PREFERENCES_PATH}/${PathConstants.HOME.PREFERENCES.DATA_ENTRY.ROOT}`;
export const PREFERENCES_SUBSCRIPTIONS_PATH = `${PREFERENCES_PATH}/${PathConstants.HOME.PREFERENCES.SUBSCRIPTIONS.ROOT}`;

export const getSubscriptionObject = (
    currentSubscription: CurrentSubscription,
    subscriptions: Subscription[],
) => {
    const currentSubInfo = subscriptions.filter(
        (key) => key.subscriptionId === currentSubscription.id,
    );
    return currentSubInfo?.[0] ?? null;
};

export function getBaseApiUrl(hostname: string, port: Nullable<string> = null) {
    if (port && port !== '443') {
        return `https://${hostname}:${port}/api`;
    }
    return `https://${hostname}/api`;
}

export function getBaseUrl(hostname: string, port: Nullable<string> = null) {
    if (port && port !== '443') {
        return `https://${hostname}:${port}`;
    }
    return `https://${hostname}`;
}
