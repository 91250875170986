import { Order } from '@local/web-design-system';
import { Dispatch } from 'redux';
import { ReplaySubject } from 'rxjs';
import store from 'store';

import {
    CONFIGURATION_TYPES,
    CACHED_SORT_OPTIONS_KEY,
    DATATABLE_TYPES,
} from 'state-domains/constants';

import { AsyncStateError, BaseAction, Nullable, ShimState } from '../types';
import { typeComplete, typeFail, typePending } from '../utils';

/* eslint-disable */

export type ObservableAction = (dispatch: Dispatch<any>, getState: Function) => ReplaySubject<any>;

/**
 * This is used to wrap the inner anonymous function for an action.
 *
 * Normally when an action is dispatched, we dispatch an inner function to be caught by the redux-thunk.
 * The thunk will call the inner function and pass in the dispatch function and the getState function.
 *
 * This wrapper is intended to be called by the thunk,
 * and create our own custom dispatch object to pass to the inner action func.
 * This custom dispatch object is used to remove some of the boilerplate from our actions.
 * It can also be subscribed to, so we know when actions have completed or failed.
 *
 * Usage:
 *
 *      function action(arg1, arg2) {
 *          return domainAction('actionType', (dispatcher: Dispatcher<PayloadType>) => {
 *              dispatcher.pending(payload);
 *              const shimState = dispatcher.getState();
 *              dispatcher.action(anotherDomainAction(...args)).subscribe(
 *                  (actionPayload) => actionWasSuccessful..., (actionPayload) => actionFailed...
 *              );
 *              ...
 *          });
 *      }
 *
 * @param actionType The type for the action payload.
 * @param thunkFunction The anonymous inner function of the action.
 * @returns The replay subject for subscribing.
 */
export function domainAction<T extends Record<string, any>>(
    actionType: string,
    thunkFunction: (dispatcher: Dispatcher<T>) => void,
) {
    return (dispatch: Dispatch<any>, getState: () => ShimState): ReplaySubject<T> => {
        const replaySubject = new ReplaySubject<T>(1);
        const dispatcher = new Dispatcher(actionType, dispatch, getState, replaySubject);
        thunkFunction(dispatcher);
        return replaySubject;
    };
}

/**
 * A custom redux dispatcher for removing boilerplate.
 *
 * Used in conjunction with the domainAction inner function wrapper.
 */
export class Dispatcher<PayloadType> {
    actionType: string;

    getReduxState: () => ShimState;

    reduxDispatch: Dispatch<any>;

    replaySubject: ReplaySubject<any>;

    constructor(
        actionType: string,
        reduxDispatch: Dispatch<any>,
        getState: () => ShimState,
        replaySubject: ReplaySubject<any>,
    ) {
        this.getReduxState = getState;
        this.actionType = actionType;
        this.reduxDispatch = reduxDispatch;
        this.replaySubject = replaySubject;
    }

    action<T extends ObservableAction>(action: T): ReturnType<T> {
        // Send an action function to the redux-thunk.
        // Dispatch expects an action so we have to change the type to any.
        // The action must be one that was wrapped with `domainAction` which returns a replay subject.
        return this.reduxDispatch(action as any);
    }

    getState(): ShimState {
        return this.getReduxState();
    }

    pureDispatch(dispatchObject: BaseAction) {
        this.reduxDispatch(dispatchObject);
    }

    dispatch(payload: Nullable<Partial<PayloadType>> = null) {
        const dispatchObject: BaseAction = {
            payload,
            type: this.actionType,
        };
        this.pureDispatch(dispatchObject);
    }

    pending(payload: Nullable<Partial<PayloadType>> = null) {
        const dispatchObject: BaseAction = {
            payload,
            type: typePending(this.actionType),
        };
        this.pureDispatch(dispatchObject);
    }

    complete(payload: Nullable<Partial<PayloadType>> = null) {
        const dispatchObject: BaseAction = {
            payload,
            type: typeComplete(this.actionType),
        };
        this.pureDispatch(dispatchObject);
        this.replaySubject.next(payload); // Notify subscribers that the action has completed.
    }

    fail(payload: Nullable<Partial<PayloadType>> = null) {
        const dispatchObject: BaseAction = {
            payload,
            type: typeFail(this.actionType),
        };
        this.pureDispatch(dispatchObject);
        this.replaySubject.error(payload); // Notify subscribers that the action has failed.
    }

    notifyComplete() {
        this.replaySubject.next(null);
    }

    notifyFailed() {
        this.replaySubject.error(null);
    }
}

export const downloadFile = (urlOrText: string, fileName = '', isUrlOrText = true) => {
    if (!urlOrText) return;

    const link = document.createElement('a');
    link.id = `temp-link-${urlOrText}`;

    link.setAttribute(
        'href',
        isUrlOrText ? urlOrText : `data:text/plain;charset=utf-8,${encodeURIComponent(urlOrText)}`,
    );
    if (fileName) {
        link.setAttribute('download', fileName);
    }

    link.style.display = 'none';
    document.body.appendChild(link);

    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
        new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window,
        }),
    );
    document.body.removeChild(link);
};
export interface ActionFunc<A extends any[], T, R> {
    (...args: A): R;
    bypass: T;
}

export function createAction<T extends Record<string, any>, A extends any[]>(
    actionType: string,
    actionFunc: (dispatcher: Dispatcher<T>, ...args: A) => void,
) {
    function returnFunc(...args: A) {
        return (dispatch: Dispatch<any>, getState: () => ShimState): ReplaySubject<T> => {
            const replaySubject = new ReplaySubject<T>(1);
            const dispatcher = new Dispatcher(actionType, dispatch, getState, replaySubject);
            actionFunc(dispatcher, ...args);
            return replaySubject;
        };
    }
    returnFunc.bypass = actionFunc; // Useful for unit testing.
    return returnFunc as ActionFunc<A, typeof actionFunc, ReturnType<typeof returnFunc>>;
}

export function mergeObjectAtLevel<T extends Record<string, any>>(
    state: T,
    level: string[],
    mergeData: Record<string, any>,
): T {
    if (level.length > 0) {
        const [nextLevel, ...rest] = level;
        const nextState = { ...(state[nextLevel] || {}) };
        return { ...state, [nextLevel]: mergeObjectAtLevel(nextState, rest, mergeData) };
    }
    return { ...state, ...mergeData };
}

export function replaceObjectAtLevel<T extends Record<string, any>>(
    state: T,
    level: string[],
    replaceData: Record<string, any>,
): T {
    if (level.length > 0) {
        const [nextLevel, ...rest] = level;
        const nextState = { ...(state[nextLevel] || {}) };
        return { ...state, [nextLevel]: replaceObjectAtLevel(nextState, rest, replaceData) };
    }
    return replaceData as T;
}

// Define a type `StringProperty` that is a property of an object, and has a value that is a string.
// Note: We've used all four brackets on the next line!
type StringProperty<T> = { [K in keyof T]: T[K] extends string ? K : never }[keyof T];

/**
 * Create a function to compare the string properties of two object instances.
 *
 * This function **returns a function** that can compare two objects of type `<T>`, and returns
 *   - `-1` if the `property` of the first object is less than the `property` of the second object,
 *   - `0` if the two properties of the two objects are equal, or
 *   - `1` if the `property` of the first object is greater than the `property` of the second object.
 *
 * The actual comparison is done by `String.prototype.localeCompare`. However, the returned function also
 * handles either of the objects being compared being `undefined`, and also either of the object properties
 * being `undefined.
 *
 * @param property The property of the `<T>` class to compare
 *
 */
export function string_comparison<T>(property: StringProperty<T>) {
    function comparison_function(a: T, b: T): number {
        let retval;
        // Objects `a`, `b`, or both may be pending.
        const propertyA = a?.[property] ?? null;
        const propertyB = b?.[property] ?? null;

        if (propertyA === null && propertyB === null) {
            retval = 0;
        } else if (propertyA === null) {
            retval = -1;
        } else if (propertyB === null) {
            retval = 1;
        } else {
            // The type checker is happy with `StringProperty` up to this point. Go to the unit.tests.ts and change the
            //   type of `ICompared.thing` to `number` to see it in action.
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            retval = a[property].localeCompare(b[property]);
        }
        return retval;
    }
    return comparison_function;
}

export interface MXDTimestamp {
    date?: number;
}

export function getErrorFromState(error: AsyncStateError) {
    // this gets called with empty errors a lot, so it's best to put a guard here
    if (!error || (!error.message && !error.response && !error.status)) return {};
    if (typeof error.response === 'string') {
        const { errorText, i18nKey } = convertErrorMessageToKey(error.response, error.status);
        error.message = errorText;
        error.originalMessage = error.response;
        return { error, i18nKey };
    }
    const errors = [];
    if (error.response?.error) {
        errors.push(error.response?.error);
    } else if (error.response?.errors) {
        const responseErrors = error.response?.errors ?? [];
        for (const e in responseErrors) {
            if (responseErrors[e] instanceof Array) {
                for (const item of responseErrors[e]) {
                    errors.push(item);
                }
            }
        }
    }

    if (errors.length < 1 && error.message && typeof error.message === 'string') {
        errors.push(error.message);
    }

    const { errorText, i18nKey } = convertErrorMessageToKey(errors.join(', '), error.status);
    error.originalMessage = error.message;
    error.message = errorText;
    return { error, errorMessage: error.message, i18nKey };
}

export const convertErrorMessageToKey = (errorText: string, status?: number) => {
    let i18nKey = true;

    switch (status) {
        case 401:
        case 403:
            errorText = 'unauthorized';
            break;
        case 404:
            errorText = 'resourceNotFound';
            break;
        case 0:
        case 500:
            errorText = 'internalServerError';
            break;
        case 503:
            errorText = 'serverUnavailable';
            break;
        case 504:
            errorText = 'gatewayTimeoutError';
            break;
        default:
            i18nKey = false;
            break;
    }

    return { errorText, i18nKey };
};

export const cacheConfigurationSortOptions = (
    userId: string,
    subscriptionId: string,
    configType: CONFIGURATION_TYPES | DATATABLE_TYPES,
    sortKey?: string,
    sortDirection?: Order,
) => {
    const allForUser = store.get(`${CACHED_SORT_OPTIONS_KEY}_${userId}_${subscriptionId}`, {});
    let keyToSave = '';
    if (!!sortKey && !!sortDirection) {
        keyToSave = sortDirection === Order.DESCENDING ? '-' : '';
        keyToSave = `${keyToSave}${sortKey}`;
    }
    const updated = {
        ...allForUser,
        configuration: {
            ...(allForUser.configuration && { ...allForUser.configuration }),
            [configType]: keyToSave,
        },
    };
    store.set(`${CACHED_SORT_OPTIONS_KEY}_${userId}_${subscriptionId}`, { ...updated });
};

export const getConfigurationSortOptionsFromCache = (
    userId: string,
    subscriptionId: string,
    configType: CONFIGURATION_TYPES | DATATABLE_TYPES,
) => {
    const allForUser = store.get(`${CACHED_SORT_OPTIONS_KEY}_${userId}_${subscriptionId}`, {});
    return allForUser.configuration?.[configType] ?? '';
};

export function createFilterFromSearchTerm(searchTerm: string) {
    return { system_filter: 'collar_number', operator: 'contains', values: [searchTerm] };
}
