import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { convertToCamel } from 'state-domains/utils/typeCast';

import { buildProjectFileGroupsUrl } from '../../../network/urls';
import { sendRetrieveRequest } from '../../../utils';
import { ProjectFileGroup } from '../types';

export const loadProjectFileGroups = (projectId = ''): Observable<ProjectFileGroup[]> => {
    const obs = sendRetrieveRequest(buildProjectFileGroupsUrl(projectId));
    return obs.pipe(
        switchMap(({ response }: AjaxResponse<any>) => {
            const result = convertToCamel<ProjectFileGroup[]>(response.ProjectFileGroup);
            return observableOf(result);
        }),
    );
};
