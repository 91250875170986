import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { buildProjectThumbnailFileUrl } from '../../../network/urls';
import { convertToCamel, sendRequestWithXSRFToken } from '../../../utils';

export const deleteProjectThumbnail = (groupId: string, fileId: string): Observable<any> => {
    const url = buildProjectThumbnailFileUrl(groupId, fileId);
    return sendRequestWithXSRFToken(url, '', 'DELETE').pipe(
        switchMap(({ response }: AjaxResponse<any>) =>
            observableOf({ ...convertToCamel<any>(response) }),
        ),
    );
};
