import { setUserContext, trackError, trackPage } from '@local/metrics';
import AppsMenuButton from '@local/waffle-menu/dist/AppsMenuButton';
import { AppBar, NavDrawer, darkTheme, theme } from '@local/web-design-system';
import { Backdrop, Grid, Link, Typography } from '@mui/material';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { compose } from 'redux';
import store from 'store';

import { USE_NEW_APP_COOKIE } from 'state-domains/constants';
import { userState } from 'state-domains/domain';
import { getClassicMXDepositUrl, getCookie } from 'state-domains/utils';

import { getAppTitle, Logo, LogoTitle } from 'src/branding';
import { ContentLoading } from 'src/components';
import {
    MXDepositRoutes,
    getNavPathForRoute,
    setDocumentTitle,
    titles,
    PathConstants,
    getSubscriptionObject,
} from 'src/routes';
import { mxdNavItems } from 'src/routes/MXDepositNavItems';
import { usePrevious } from 'src/utilities/usePrevious';

import {
    toggleNavDrawer as toggleDrawerAction,
    isNavDrawerOpen as isDrawerOpenSelector,
} from '../../state/Drawer';
import { AccountSelect } from '../AccountSelect';
import { NotificationsButton } from '../Notifications/NotificationsButton';
import { NotificationsContextProvider } from '../NotificationsContext';
import {
    NotificationsRemovingStrategy,
    NotificationsShowingType,
} from '../NotificationsContext/NotificationsContext.types';
import { UserPortalProfile } from '../UserPortalProfile';
import { WebSocketManager } from '../WebSocketManager';
import { withAuth, withCurrentUser } from '../withDomain';
import { i18n } from './App.i18n';
import { useStyles } from './App.styles';
import { UserPortalAllProps } from './App.types';

export function MXDepositBase(props: UserPortalAllProps) {
    const {
        selectors: {
            currentUserId: currentUserIdSelector,
            currentSubscription: currentSubscriptionSelector,
            subscriptions: subscriptionsSelector,
        },
    } = userState;

    const { mxdWebSockets } = useFlags();

    const currentSubscription = useSelector(currentSubscriptionSelector);
    const subscriptions = useSelector(subscriptionsSelector);
    const currentSubscriptionObject = getSubscriptionObject(currentSubscription, subscriptions);
    const currentUserId = useSelector(currentUserIdSelector);
    const isDrawerOpen = useSelector(isDrawerOpenSelector);

    const [isReactAppDisabled, setIsReactAppDisabled] = React.useState(false);
    const [lastPath, setLastPath] = React.useState('');

    const dispatch = useDispatch();
    const toggleDrawer = toggleDrawerAction(dispatch);

    const location = useLocation();
    const intl = useIntl();
    const prevLocation = usePrevious(location);
    const { classes } = useStyles();

    const getTitleForLocation = (pathname: string) => {
        switch (pathname) {
            case PathConstants.PROJECTS.ROOT:
                return titles.projects;
            case PathConstants.HOME.ROOT:
                return titles.home;
            case PathConstants.EVENTS.ROOT:
                return titles.events;
            case PathConstants.CONFIGURATION.ROOT:
                return titles.configuration;
            default:
                return null;
        }
    };

    const isUseNewAppCookie = () => {
        const newAppCookie = getCookie(USE_NEW_APP_COOKIE) ?? 'true';
        return newAppCookie === 'true';
    };

    React.useEffect(() => {
        const onWindowFocus = (_event: FocusEvent) => {
            setIsReactAppDisabled(!isUseNewAppCookie());
        };
        window.addEventListener('focus', onWindowFocus);
        updatePageTitle();
        return () => {
            window.removeEventListener('focus', onWindowFocus);
        };
    }, []);

    React.useEffect(() => {
        // didUpdate
        if (location.pathname !== prevLocation?.current?.pathname) {
            updatePageTitle();
        }

        // Set the metric context for tracking user through Datadog/Segment
        if (window.location.pathname !== lastPath && currentUserId) {
            setLastPath(window.location.pathname);
            trackPage()
                .then()
                .catch((error) => {
                    trackError(error.message);
                    // eslint-disable-next-line no-console
                    console.warn(error.message);
                });
            setUserContext({
                userId: currentUserId,
                orgId: currentSubscription.id,
            })
                .then()
                .catch((error) => {
                    trackError(error.message);
                    // eslint-disable-next-line no-console
                    console.warn(error.message);
                });
        }
    }, [location, currentSubscription, currentUserId]);

    React.useEffect(() => {
        store.set('userContext', {
            id: currentUserId,
            subscriptionId: currentSubscription?.id ?? '',
        });
    }, [currentUserId, currentSubscription]);

    const toggleNav = React.useCallback(
        (e: MediaQueryListEvent) => {
            if (e.matches && isDrawerOpen) {
                toggleDrawer();
            }
        },
        [isDrawerOpen],
    );

    React.useEffect(() => {
        // This automatically closes the nav drawer when window is less than or equal to the
        // width specified below and opens it when window is greater than width specified below
        const x = window.matchMedia('(max-width: 1534px)');

        x.addEventListener('change', toggleNav);
        return () => x.removeEventListener('change', toggleNav);
    }, [isDrawerOpen]);

    function updatePageTitle() {
        // Parse pathname to determine title for page
        const titleChunk = getTitleForLocation(location.pathname);
        if (titleChunk) {
            setDocumentTitle([getAppTitle(), intl.formatMessage(titleChunk)]);
        }
    }

    const navRouteInfo = getNavPathForRoute(props);

    const navItems = mxdNavItems(currentSubscriptionObject, intl);

    return (
        <MuiThemeProvider theme={theme}>
            {mxdWebSockets && <WebSocketManager />}
            <NotificationsContextProvider
                notificationsLimit={5}
                removingStrategy={
                    NotificationsRemovingStrategy.AUTO_REMOVE_EXCEPT_LAST |
                    NotificationsRemovingStrategy.AUTO_REMOVE_LAST
                }
                fadingOutOnAutoRemoving
                showingType={NotificationsShowingType.MOST_RECENT_TOP}
                isDrawerOpen={isDrawerOpen}
            >
                {/* Application layout */}
                <div className={classes.root}>
                    <Backdrop
                        automation-id="MXDeposit-backdrop"
                        sx={{ zIndex: 9999 }}
                        open={isReactAppDisabled || !isUseNewAppCookie()}
                    >
                        <Typography sx={{ color: '#FFF' }}>
                            {intl.formatMessage(i18n.useEap)}
                            <Link
                                sx={{ paddingLeft: '8px' }}
                                href={getClassicMXDepositUrl() ?? 'https://dev.mxdeposit.net'}
                            >
                                Go to Classic MX Deposit
                            </Link>
                        </Typography>
                    </Backdrop>
                    {/* Navigation drawer */}
                    <MuiThemeProvider theme={darkTheme}>
                        <Grid automation-id="MXDeposit-nav-drawer">
                            <NavDrawer
                                isDrawerOpen={isDrawerOpen}
                                navItems={navItems}
                                currentRoute={navRouteInfo.route}
                                toggleDrawer={toggleDrawer}
                                logoWithTitle={<LogoTitle />}
                                logoOnly={<Logo />}
                            />
                        </Grid>
                    </MuiThemeProvider>
                    {/* Top application bar */}
                    <AppBar
                        classes={{ profileContainer: classes.profileComponent }}
                        isDrawerOpen={isDrawerOpen}
                        toggleDrawer={toggleDrawer}
                        contentComponent={
                            <Grid container justifyContent="flex-end">
                                <Grid item className={classes.buttonWrapper}>
                                    <AccountSelect />
                                </Grid>
                                <Grid item>
                                    <NotificationsButton />
                                </Grid>
                            </Grid>
                        }
                        profileComponent={<UserPortalProfile useShortDivider />}
                        additionalComponents={
                            <Grid container justifyContent="flex-end">
                                <Grid item>
                                    <AppsMenuButton />
                                </Grid>
                            </Grid>
                        }
                    />
                    {/* Main content area */}
                    <main className={classes.content} data-automation-id="MXDeposit-app-routing">
                        <React.Suspense
                            fallback={<ContentLoading isFailed={false} skeletonLoading />}
                        >
                            {/* Activated route component will render here */}
                            <MXDepositRoutes />
                        </React.Suspense>
                    </main>
                </div>
            </NotificationsContextProvider>
        </MuiThemeProvider>
    );
}

export const App = compose(withAuth, withCurrentUser)(MXDepositBase);
