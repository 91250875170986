import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { evoCheckPermissions } from '../../../network/urls';
import { sendRetrieveRequest } from '../../../utils';

export const checkEvoPermissions = (workspaceId: string): Observable<{ permitted: boolean }> => {
    const url = evoCheckPermissions(workspaceId);
    return sendRetrieveRequest(url).pipe(
        switchMap(({ response }: AjaxResponse<{ permitted: boolean }>) => observableOf(response)),
    );
};
