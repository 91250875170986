import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { ProjectFileGroup } from 'state-domains/domain/project';

import { buildProjectFileGroupsUrl } from '../../../network/urls';
import { convertToCamel, sendRequestWithXSRFToken } from '../../../utils';

export const addProjectFileGroup = (payload: any): Observable<ProjectFileGroup> => {
    const url = buildProjectFileGroupsUrl();
    return sendRequestWithXSRFToken(url, payload, 'POST').pipe(
        switchMap(({ response }: AjaxResponse<ProjectFileGroup>) => {
            const fileGroup = convertToCamel<ProjectFileGroup>(response);
            return observableOf(fileGroup);
        }),
    );
};
