import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';

import { evoConfigurationUrl } from 'state-domains/network';
import { convertToCamel, convertToSnake } from 'state-domains/utils/typeCast';

import { sendRequestWithXSRFToken, sendRetrieveRequest } from '../../../utils';
import { EvoConfigurationState } from '../../integrations/types';

export const evoConfiguration = (
    type: 'GET' | 'POST',
    payload?: EvoConfigurationState,
): Observable<EvoConfigurationState> => {
    let updatedPayload = {};
    if (payload) {
        updatedPayload = {
            goose: {
                ...convertToSnake(payload),
                projects_map: payload?.projectsMap,
            },
        };
    }
    const url = evoConfigurationUrl();
    const obs =
        type === 'GET' ? sendRetrieveRequest(url) : sendRequestWithXSRFToken(url, updatedPayload);
    return obs.pipe(
        switchMap(({ response }: AjaxResponse<any>) => {
            const config = convertToCamel<any>(response);
            // When POST, api returns nothing so we have to update locally using our payload or we can make another GET call
            const returnVal = type === 'GET' ? config?.gooseConfig : payload;
            return observableOf(returnVal);
        }),
    );
};
